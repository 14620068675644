import React, { useEffect, useState } from "react";
import styles from "../../components/tradeall.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { RingLoader } from "react-loader-spinner";
import RingLoader from "react-spinners/RingLoader";
import crossicon from "../../assets/cross.png";
import { IoArrowBack } from "react-icons/io5";

const AllUsersTable = () => {
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState([]);
  const [Loading, setLoading] = useState(true);
  const getAllUsers = async () => {
    try {
      const tokenfromls = localStorage.getItem("token");
      const h = { authorization: tokenfromls };
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/user/getusersdata`,
        { headers: h }
      );
      setLoading(false);
      console.log("data", response.data);
      setAllUsers(response.data);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const [Ismodalopenforuserinfo, setIsModalOpenforuserinfo] = useState(false);
  const [userstradesfullinfo, setUserstradesfullinfo] = useState([]);
  const handleRowClick = (email) => {
    console.log("Selected email:", email);
    getUsersdata(email); // Pass the email to getUsersdata
    setIsModalOpenforuserinfo(true);
  };

  const getUsersdata = async (email) => {
    try {
      console.log("email", email);
      // const tokenfromls = localStorage.getItem("token");
      // const h = { authorization: tokenfromls };
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/getSavedTradingData_ByEmail/${email}`
      );
      console.log("response", response);
      setLoading(false);

      const data1 = response.data.documents?.flatMap((item) => item.documents);

      // Flatten `trades` array within `data1`.
      const data2 = data1?.flatMap((doc) => doc.trades);

      if (response.status === 200) {
        setUserstradesfullinfo(data2);
        setLoading(false);
      } else if (response.status === 404) {
        setUserstradesfullinfo([]);
        setLoading(false);
      } else {
        setUserstradesfullinfo(data2);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setUserstradesfullinfo([]);
    }
  };
  const closeModalforuserinfo = () => {
    setIsModalOpenforuserinfo(false);
  };

  const handleStatusChange = async (useremail, newStatus) => {
    console.log("useremail", useremail);
    console.log("newStatus", newStatus);
    try {
      await axios.put(`${process.env.REACT_APP_URL}/approve/${useremail}`, {
        approvalStatus: newStatus,
      });
      const updatedUsers = allUsers.map((user) =>
        user.email === useremail ? { ...user, approvalStatus: newStatus } : user
      );
      console.log("updatedUsers", updatedUsers);
      setAllUsers(updatedUsers);
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };

  return (
    <>
      {Loading ? (
        <div className="flex items-center justify-center  mt-10 w-full">
          <RingLoader
            height="80"
            width="80"
            color="#94A3B8"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            className=" flex items-center justify-center  mt-10 w-[100%]"
          />
        </div>
      ) : (
        <>
          <div className="max-w-[100%] mx-[4%]  mt-[10vh] sm:mt-[10vh] md:mt-[10vh] lg:mt-[10vh] xl:mt-[10vh]">
            <IoArrowBack
              className="mb-2 cursor-pointer "
              onClick={() => navigate("/admin/economicdata")}
              title="Back Button"
            />
            <div className="overflow-auto">
              <div className="overflow-x-auto overflow-y-auto  max-h-[65vh] sm:max-h-[65vh] md:max-h-[65vh] lg:max-h-[65vh] xl:max-h-[65vh]">
                <table
                  className={`${styles.tableuser} min-w-full  rounded overflow-x-scroll m-auto text-[16px] font-semibold`}
                >
                  <thead
                    key={2}
                    className="bg-[#d0dcf4]   text-[#170E32]  sticky top-0 z-10 text-[16px] font-semibold border-b-[12px] border-solid border-[#F6F6F6] rounded-[8px]"
                  >
                    <tr key={1} className="h-[96px]">
                      <th className="sticky top-0 py-1 px-1">S.No.</th>
                      <th className="sticky top-0 py-1 px-1">Name</th>
                      <th className="sticky top-0 py-1 px-1">Email</th>
                      <th className="sticky top-0 py-1 px-1">Phonenumber</th>
                      <th className="sticky top-0 py-1 px-1">City</th>
                      <th className="sticky top-0 py-1 px-1">Country</th>
                      {/* <th className="sticky top-0 py-1 px-1">Broker</th>
                <th className="sticky top-0 py-1 px-1">Broker Id</th> */}
                      <th className="sticky top-0 py-1 px-1">Approve Users</th>
                    </tr>
                  </thead>
                  <tbody className="bg-[#F6F6F6]  z-0">
                    {allUsers.map((item, index) => (
                      <tr
                        key={item._id}
                        className={` text-center border-y-[12px] cursor-pointer border-solid border-[#F6F6F6]  
              bg-white hover:bg-[#aecaf133]
               `}
                      >
                        <td className="py-1 px-1">{index + 1}.</td>
                        <td
                          className="py-1 px-1"
                          onClick={() => handleRowClick(item.email)}
                        >
                          {item.name}
                        </td>
                        <td
                          className="py-1 px-1"
                          onClick={() => handleRowClick(item.email)}
                        >
                          {item.email}
                        </td>
                        <td
                          className="py-1 px-1"
                          onClick={() => handleRowClick(item.email)}
                        >
                          {item.phonenumber}
                        </td>
                        <td
                          className="py-1 px-1 ml-[-5px] text-blue-500 "
                          onClick={() => handleRowClick(item.email)}
                        >
                          {item.city}
                        </td>
                        <td
                          className="py-1 px-1"
                          onClick={() => handleRowClick(item.email)}
                        >
                          {item.country}
                        </td>
                        <td className="py-1 px-1">
                          <select
                            value={item.approvalStatus}
                            onChange={(e) =>
                              handleStatusChange(item.email, e.target.value)
                            }
                            className="p-2 border rounded"
                          >
                            <option value="pending">Pending</option>
                            <option value="approved">Approved</option>
                            <option value="rejected">Rejected</option>
                          </select>
                        </td>
                        {/* <td className="py-1 px-1">{item.broker}</td>
                  <td className="py-1 px-1">{item.brokerid}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}

      {Ismodalopenforuserinfo && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[999]"
          onClick={closeModalforuserinfo}
        >
          <div
            className="bg-white rounded-lg p-4 w-[70%] md:w-[75%] relative  top-0 sm:top-0 md:top-0 lg:top-[2%] xl:top-0  max-h-[64vh] overflow-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={closeModalforuserinfo}
              className="absolute top-4 right-4 h-4 w-4 text-gray-600 hover:text-gray-900"
              title="Close"
            >
              {/* &times;  */}
              <img
                src={crossicon}
                alt="crossicon"
                className="h-[12px] w-[12px]"
              />
            </button>
            <h2 className="text-lg font-bold mb-4 text-[#151841]">
              Trade History of user :
            </h2>

            <>
              {/* <div className="flex items-end justify-end mb-4"> 
                              <h1 className="flex  justify-center text-white bg-[#5b6b8b] border border-solid border-[#5b6b8b] p-1 items-end cursor-pointer font-bold rounded-md " title="Click here to save your trade"     onClick={handleFinalSubmission} >Repopulate this entry    <FiCheckCircle className="ml-1 cursor-pointer" title="Save your data" onClick={handleFinalSubmission}/></h1>
                              </div> */}
              <div className="overflow-auto">
                <div className="overflow-x-auto overflow-y-auto  ">
                  <table
                    className={` ${styles.tableuser} min-w-full  rounded overflow-x-scroll m-auto text-[16px] font-semibold`}
                  >
                    <thead className="bg-[#d0dcf4]   text-[#170E32]  sticky top-0 z-10 text-[16px] font-semibold border-b-[12px] border-solid border-[#F6F6F6] rounded-[8px]">
                      <tr>
                        <th className=" sticky top-0 py-2 px-4">S. No.</th>
                        <th className=" sticky top-0 py-2 px-4">Date</th>
                        <th className=" sticky top-0 py-2 px-4">Time(GMT)</th>
                        <th className="sticky top-0 py-2 px-4">Country</th>
                        <th className="sticky top-0 py-2 px-4">Currency</th>
                        <th className="sticky top-0 py-2 px-4">Event</th>
                        <th className="sticky top-0 py-2 px-4">Trade</th>
                        <th className="sticky top-0 py-2 px-4">Trade Grade</th>
                        <th className="sticky top-0 py-2 px-4">
                          Investing.com event name
                        </th>
                        <th className="sticky top-0 py-2 px-4">
                          Tradingview.com event name
                        </th>
                        <th className="sticky top-0 py-2 px-4">
                          Tradingeconomics.com event name
                        </th>
                        <th className="sticky top-0 py-2 px-4">
                          Non - Zero (Currency)
                        </th>
                        <th className="sticky top-0 py-2 px-4">
                          Non - Zero (Correlation)
                        </th>
                        <th className="sticky top-0 py-2 px-4">
                          Flat (Currency)
                        </th>
                        <th className="sticky top-0 py-2 px-4">
                          Flat (Correlation)
                        </th>
                        <th className="sticky top-0 py-2 px-4">Comments</th>
                        <th className="sticky top-0 py-2 px-4">Prediction</th>
                        <th className="sticky top-0 py-2 px-4">
                          Trade log(profit/loss)
                        </th>
                        <th className="sticky top-0 py-2 px-4">Trade Amount</th>
                        <th className="sticky top-0 py-2 px-4">
                          Trade in time(seconds)
                        </th>
                        <th className="sticky top-0 py-2 px-4">
                          Trade comment
                        </th>
                        {/* <th className="sticky top-0 py-2 px-4">
                                  Select Trade
                                </th> */}
                      </tr>
                    </thead>
                    <tbody className="bg-[#F6F6F6]  z-0">
                      {userstradesfullinfo.length === 0 ? (
                        <div
                          className={`${styles.data_missing} m-2 mt-[40%] sm:mt-[40%] md:mt-10 lg:mt-5 xl:mt-5 text-center font-semibold text-xl `}
                        >
                          <h1 className="">
                            {" "}
                            Sorry!! No history available of this user .
                          </h1>
                        </div>
                      ) : (
                        <>
                          {userstradesfullinfo
                            .sort((a, b) => new Date(b.date) - new Date(a.date))
                            .map((item, index) => (
                              <>
                                <tr
                                  key={index}
                                  className="text-center border-y-[12px] border-solid border-[#F6F6F6] bg-white hover:bg-[#aecaf133]"
                                >
                                  {/* { console.log("modalData" , modalData)} */}
                                  <td className="py-2 px-4 min-w-[80px]">
                                    {index + 1}.
                                  </td>
                                  <td className="py-2 px-4">{item.date}</td>
                                  <td className="py-2 px-4">{item.time}</td>
                                  <td className="py-2 px-4">{item.country}</td>
                                  <td className="py-2 px-4">{item.currency}</td>
                                  <td className="py-2 px-4 ml-[-5px] text-blue-500 cursor-pointer min-w-[200px]">
                                    {item.event}
                                  </td>

                                  <td className="py-2 px-4">{item.trade}</td>
                                  <td className="py-2 px-4">
                                    {item.trade_grade}
                                  </td>
                                  <td className="py-2 px-4 min-w-[200px]">
                                    {item.investing_name}
                                  </td>
                                  <td className="py-2 px-4 min-w-[200px]">
                                    {item.trading_view}
                                  </td>
                                  <td className="py-2 px-4 min-w-[200px]">
                                    {item.trading_economics}
                                  </td>
                                  <td className="py-2 px-4">
                                    {item.movement_currency}
                                  </td>
                                  <td className="py-2 px-4">
                                    {item.movement_correlation}
                                  </td>
                                  <td className="py-2 px-4">
                                    {item.flat_currency}
                                  </td>
                                  <td className="py-2 px-4">
                                    {item.flat_correlation}
                                  </td>
                                  <td className="py-2 px-4 min-w-[200px]">
                                    {item.comments}
                                  </td>
                                  <td className="py-2 px-4">
                                    {item.prediction}
                                  </td>
                                  <td className="py-2 px-4">
                                    {item.trade_log}
                                  </td>
                                  <td className="py-2 px-4">
                                    {item.trade_amount}
                                  </td>
                                  <td className="py-2 px-4">
                                    {item.trade_time}
                                  </td>
                                  <td className="py-2 px-4 min-w-[200px]">
                                    {item.trade_comments}
                                  </td>
                                </tr>
                              </>
                            ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default AllUsersTable;
