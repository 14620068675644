import React , {useState} from 'react'

const Widgetpage = () => {
    const [iframeHeight2, setIframeHeight2] = useState("46vh");




  return (
   <>
   
   <div className="flex space-x-4 chart_button mt-3 justify-center items-center">
          <button
            onClick={() => setIframeHeight2("29vh")}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          >
            Reduce Height
          </button>
          <button
            onClick={() => setIframeHeight2("46vh")}
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
          >
            Reset Height
          </button>
        </div>
      <div className="flex justify-center items-center mt-1">
        <iframe
          title="Uk investing"
          src="https://www.ig.com/content/dam/publicsites/dev/mt4/MT4_WEB.html"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen=""
          className=" block   border-[1px]  border-slate-300  sm:border-[0px]  w-[95vw] sm:w-[100vw] md:w-[100vw] lg:w-[100vw] xl:w-[60vw] rounded-2xl"
          
          style={{ height: iframeHeight2 }}
        />
      </div>
   </>
  )
}

export default Widgetpage
