import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import {LineWave} from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/altrafxlogo_black.png";
import bannerimg from "../../assets/userlogin.webp";
import { useNavigate } from "react-router-dom";
// import logo from "../assets/logo.png";
import { IoIosEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";
import axios from "axios";
import { PiArrowSquareRight } from "react-icons/pi";
import styles from "../../components/tradeall.module.css"


const UserSignin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [Loading, setLoading] = useState(false);
  


  
  const verifytokenexists = async () => {
    try {
      const tokenfromls = localStorage.getItem("usertoken");
      // console.log("tokenfromls" , tokenfromls)

      const id = localStorage.getItem("userregisterId");
      const h = { authorization: tokenfromls };
      // console.log(h);
      if (tokenfromls) {
        const res = await axios.get(`
          ${process.env.REACT_APP_URL}/protect/userprotectedroute`,
          { headers: h }
        );
        // console.log("my resp", res);
       
        if (res) {
          // console.log(res.data , "jloashissh")
          if (res.data.m === id) {
            navigate("/user/economicdata");
          }
        }
      } 
    } catch (e) {
      console.log(e)
      if((e.response && e.response.status)  === 401 || (e.response && e.response.status === 400)){
        console.log("Token malfunction");
      }
    
    }
  };
  
  useEffect(() => {
    verifytokenexists()
  }, []);
  
  const validateEmail = (email) => {
    const emailRegex = /.+@.+/;
    return emailRegex.test(email);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    setIsLoggingIn(true);
    if (!validateEmail(email)) {
      toast.error("Invalid email address , @ is mendatory", {
        position: "top-right"});
        setIsLoggingIn(false);
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/user/usersignin`,
        { email, password },
        {headers: {"Content-Type": "application/json"},
        }
      );
      setLoading(false)
      setIsLoggingIn(true);
      // console.log("response" , response)
      
      const data = response.data;

console.log("data" , data)
      const { approvalStatus } = data.user; // Extract approvalStatus

      // if (approvalStatus === 'pending') {
       
      //   setIsLoggingIn(false);
      //   setLoading(false);
      //   return; // Stop further execution
      // }
  
      // if (approvalStatus === 'rejected') {
      //   toast.error("Your account has been rejected. Please contact support.", {
      //     position: "top-right",
      //   });
      //   setIsLoggingIn(false);
      //   setLoading(false);
      //   return; // Stop further execution
      // }
  
      localStorage.setItem("username" , response.data.user.name )
      const userregisterId = response.data.user._id          ;
      localStorage.setItem("userregisterId" , userregisterId )
      localStorage.setItem("usertoken", data.usertoken);
      setEmail("");
      setPassword("");

      navigate("/user/economicdata");
    } 
    // catch (error ) {
    //   setLoading(false)
    //   setIsLoggingIn(false);
    //   // console.error("Error during login:", error);
     
    //   if (error.response && error.response.data) {
    //     const errorMessage = error.response.data.message;

    //   if(errorMessage === "Wait for the admin approval"){
    //     toast.error("Your profile is in review . You will get a confirmation email once approved.", {
    //       position: "top-right",
    //     });
    //   }
    //   if(error.response.data.message === "Your registration was rejected"){
    //     toast.error("Your account has been rejected. Please contact support.", {
    //       position: "top-right",
    //     });
    //   }
    //   if(error.response.data.message === "Error in login"){
    //     toast.error("Error during login! Please check credientials ", {
    //       position: "top-right",
    //     });
    //   }
    // }
    // }
    catch (error) {
      setLoading(false);
      setIsLoggingIn(false);
    
      // Safely check for error response
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message;
    
        if (errorMessage === "Wait for the admin approval") {
          toast.error(
            "Your profile is in review. You will get a confirmation email once approved.",
            { position: "top-right" }
          );
        } else if (errorMessage === "Your registration was rejected") {
          toast.error(
            "Your account has been rejected. Please contact support.",
            { position: "top-right" }
          );
        } else if (errorMessage === "Error in login") {
          toast.error(
            "Error during login! Please check credentials.",
            { position: "top-right" }
          );
        } else {
          toast.error(
            "Error during login! Please check credientials again",
            { position: "top-right" }
          );
        }
      } else if (error.request) {
        // Handle cases where the server didn't respond
        console.error("No response received:", error.request);
        toast.error("Network error. Please check your connection.", {
          position: "top-right",
        });
      } else {
        // Handle other errors
        console.error("Error message:", error.message);
        toast.error("An unexpected error occurred. Please try again.", {
          position: "top-right",
        });
      }
    }
    
  };

  return (
    <>
      <section className={`${styles.usersignin_bg}  `}>
       
       <div className=" py-[3.5rem] sm:py-[3.5rem] md:py-[4.5rem] lg:py-[5.5rem] xl:py-[7.5rem]  px-[1.5rem] sm:px-[1.5rem] md:px-[4rem] lg:px-[5rem] xl:px-[7rem] h-full flex w-[100%] flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row">
       <div className="w-[100%] sm:w-[100%] md:w-[100%]  lg:w-[50%] xl:w-[50%] flex justify-center items-center ">
          <img
            src={bannerimg}
            alt="bannerimg"
            className="h-[35vh] sm:h-[35vh] md:h-[35vh] lg:h-[55vh] xl:sm:h-[55vh]"
          ></img>
        </div>
        <div className=" w-[100%] z-[999] sm:w-[100%] md:w-[100%]  lg:w-[50%] xl:w-[50%] flex flex-col items-center justify-center  py-8 lg:py-0 " >
          

       <div className="rounded-[34px] w-full flex items-center justify-center sm:px-[2px] md:px-[6.5rem] lg:px-[4.5rem] xl:px-[4.5rem] px-2 py-0 sm:py-0 md:py-[3.5rem] lg:py-[4.5rem] xl:py-[7.5rem]  bg-white" style={{ boxShadow: '0px 2px 14.1px 0px rgba(223, 223, 223, 0.50)' }}>
       <div className="w-full bg-white     md:mt-0 sm:max-w-md xl:p-0 rounded-[34px]"  >
            <div className=" space-y-4 md:space-y-4  ">
              <h1 className="text-[24px] sm:text-[24px] md:text-[32px] lg:text-[32px] xl:text-[32px] font-bold text-center text-[#151841]">Sign In</h1>

              <p className="text-[#170E32] text-center font-semibold text-[14px] sm:text-[14px] md:sm:text-[14px] lg:sm:text-[16px] xl:sm:text-[16px]  ">To access your account, please provide the <br/>following information.</p>
              <form onSubmit={handleSubmit} className="my-10">
                <div className="flex flex-col  ">
                  <label htmlFor="email" >
                    <p className="font-bold text-[#170E32] pb-2 mt-4">
                      Email address
                    </p>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="off"
                      className="w-full py-3 text-[14px] placeholder-[#545454] font-semibold  input-field border border-[#8692A6] rounded-lg px-5 focus:outline-none focus:border-slate-500 hover:shadow"
                      placeholder="Enter email"
                    />
                  </label>
                  <label htmlFor="password" className="relative mt-4">
                    <p className="font-bold text-[#170E32] pb-2">Password</p>
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="off"
                      className="w-full py-3 text-[14px] placeholder-[#545454] font-semibold input-field border border-[#8692A6] rounded-lg px-5 focus:outline-none focus:border-slate-500 hover:shadow pr-10"
                      placeholder="Enter password"
                    />
                    {showPassword ? (
                      <IoIosEye
                        className="absolute top-[70%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      // <img src={eye_img_open}  onClick={() => setShowPassword(!showPassword)} className="absolute top-[70%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500 w-[16px]"></img>
                      <IoIosEyeOff
                        className="absolute top-[70%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                      // <img src={eye_img}  onClick={() => setShowPassword(!showPassword)} className="absolute top-[70%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500 w-[16px]"></img>
                    )}
                  </label>

               
                      <a  href="/user/forgetpassword" className={`${styles.forgetpassword} cursor-pointer  text-end text-[13px]  space-y-0   font-bold   text-[#545454]`}>
                      <NavLink to="/user/forgetpassword" className="mt-3">Forgot Password?</NavLink></a>
                   
                  <button
                    type="submit"
                    className="login-btn w-full py-3 font-bold text-white bg-[#151841] hover:bg-gray-700 rounded-lg border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center mt-10"
                  >
                    <span>{isLoggingIn ? "Signing you in..." : "Sign In"}</span>
                    {/* <PiArrowSquareRight className="text-xl" /> */}
                  </button>
                  <div className="justify-center items-center flex mt-4"><p className="text-sm font-semibold text-[#170E32]">
                    Don't have an account?
                    <a
                      href="/user/signup"
                      className="font-bold text-[#151841] ml-2"
                    >
                      <NavLink to="/user/signup"> Register Here </NavLink>
                    </a>
                  </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
       </div>
      





          {
             Loading ?  <div className="fixed bottom-0 right-0 mb-2 flex items-end justify-end   w-full">
             <LineWave 
               height="60"
               width="60"
               color="#111827"
               ariaLabel="bars-loading"
               wrapperStyle={{}}
               wrapperClass=""
               visible={true}
               className=" w-10 h-10 flex items-center justify-center  mt-10 "
             />
           </div> :
            <div>
            
           </div>
          }
      
        </div>

       </div>

      </section>
      
    </>
  );
};

export default UserSignin;
