import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../components/tradeall.module.css";
import axios from "axios";
import UserNavbar from "../../components/user/userNavbar";
import { toast } from "react-toastify";
import RingLoader from "react-spinners/RingLoader";
import Selects from "react-select";
import { LineWave } from "react-loader-spinner";
import { Select, Space } from "antd";
import { DatePicker } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { FaLongArrowAltRight } from "react-icons/fa";
import { IoArrowBack } from "react-icons/io5";
const { RangePicker } = DatePicker;

const Savedtrade = () => {
  const [savedtradedatavalues, setSavedtradedatavalues] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [Loading1, setLoading1] = useState(false);
  const [refetchData, setRefetchData] = useState(false);
  const [tradeUpdates, setTradeUpdates] = useState([]);
  const [sendUpdatedItemToBackend, set_SendUpdatedItemToBackend] = useState([]);

  const [dateRange, setDateRange] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState([]);

  const [tradelogchange, setTradelogchange] = useState([]);
  const { Option } = Select;
  // const [currentPage, setCurrentPage] = useState(0);
  const [paginationData, setPaginationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); 

  const [searchTerm, setSearchTerm] = useState("");
 const [predictionChange , setPredictionChange] = useState({}) 
 const [tradeLog , settradeLog] = useState({})
 const [trade_amount , settrade_amount] = useState({})
 const [trade_time , settrade_time] = useState({})  
 const [trade_comment , settrade_comment] = useState({})    
//  const [prediction , setPrediction] = useState({})  
//  const [prediction , setPrediction] = useState({})   

const navigate = useNavigate();
  const savedtradedata = async () => {
    try {
      const tokenfromls = localStorage.getItem("usertoken");
      const h = { authorization: tokenfromls };
      const userid = localStorage.getItem("userregisterId");
      const savedresponse = await axios.get(
        `${process.env.REACT_APP_URL}/user/getsavedtradingdata/${userid}`,
        { headers: h }
      );
      setLoading(false);
      if (savedresponse.status === 200) {
        const latestDocuments = savedresponse.data.latestDocuments;

        let allTrades = [];

        latestDocuments.forEach((doc) => {
          allTrades = allTrades.concat(doc.trades);
        });



      
      // const groupedByMonth = {};

      // console.log("allTrades" , allTrades)
      // allTrades.forEach((trade) => {
      //   if (trade.date && typeof trade.date === "string") {
      //     try {

      //       // Extract the "YYYY-MM" part directly from the date string
      //       const monthYear = trade.date.slice(0, 7); // Take "YYYY.MM" from "YYYY.MM.DD"
      //       if (!groupedByMonth[monthYear]) groupedByMonth[monthYear] = [];
      //       groupedByMonth[monthYear].push(trade);
      //     } catch (error) {
      //       console.error(`Error processing trade date:`, trade, error);
      //     }
      //   } else {
      //     console.warn(`Trade missing a valid date:`, trade);
      //   }
      // });
      

      // console.log("groupedByMonth" , groupedByMonth)
      
      
      //           // Step 1: Sort groupedByMonth by month in descending order
      //     const sortedGroupedByMonth = Object.keys(groupedByMonth)
      //     .sort((a, b) => new Date(b) - new Date(a))
      //     .reduce((sortedObj, key) => {
      //       sortedObj[key] = groupedByMonth[key];
      //       return sortedObj;
      //     }, {});

      //     console.log("sortedGroupedByMonth", sortedGroupedByMonth);
      //     const paginatedData = [];
      //    // Step 2: Create paginated data, one group (month) per page
      //     const monthKeys = Object.keys(sortedGroupedByMonth);
      //     for (let i = 0; i < monthKeys.length; i += 3) {
      //       const pageData = [];
      //       // Collect 3 months for each page
      //       for (let j = i; j < i + 3 && j < monthKeys.length; j++) {
      //         pageData.push(sortedGroupedByMonth[monthKeys[j]]);
      //       }
      //       paginatedData.push(pageData);
      //     }
          
      //     console.log("paginatedData", paginatedData);
      //   // Step 2: Create paginated data, one group (month) per page
      //   // const paginatedData = Object.values(sortedGroupedByMonth);
        
      //   // Check if the first month's data has fewer than 20 items
      //   if (paginatedData[0] && paginatedData[0].length < 20 && paginatedData[1]) {
      //     // Combine current month's data with the next month's data
      //     paginatedData[0] = [...paginatedData[0], ...paginatedData[1]];
      //     // Remove the next month's data after merging
      //     paginatedData.splice(1, 1);
      //   }
      //   setSavedtradedatavalues(paginatedData[0] || []); 
      //   setPaginationData(paginatedData); 
      const groupedByMonth = {};

      // console.log("allTrades", allTrades);

      allTrades.forEach((trade) => {
        if (trade.date && typeof trade.date === "string") {
          try {
            const monthYear = trade.date.slice(0, 7); // Extract "YYYY-MM"
            if (!groupedByMonth[monthYear]) groupedByMonth[monthYear] = [];
            groupedByMonth[monthYear].push(trade);
          } catch (error) {
            console.error(`Error processing trade date:`, trade, error);
          }
        } else {
          console.warn(`Trade missing a valid date:`, trade);
        }
      });

      // console.log("groupedByMonth", groupedByMonth);

      // Sort months in descending order
      const sortedGroupedByMonth = Object.keys(groupedByMonth)
        .sort((a, b) => new Date(b) - new Date(a)) // Sorting months in descending order
        .reduce((sortedObj, key) => {
          sortedObj[key] = groupedByMonth[key];
          return sortedObj;
        }, {});

      // console.log("sortedGroupedByMonth", sortedGroupedByMonth);

      // Step 2: Create paginated data with 3 months per page
      const paginated_data = [];
      const monthKeys = Object.keys(sortedGroupedByMonth);

      // Create pages by grouping 3 months together
      for (let i = 0; i < monthKeys.length; i += 3) {
        const pageData = [];
        for (let j = i; j < i + 3 && j < monthKeys.length; j++) {
          pageData.push({ month: monthKeys[j], trades: sortedGroupedByMonth[monthKeys[j]] });
        }
        paginated_data.push(pageData);
      }

      // console.log("paginated_data", paginated_data);

      // Step 3: Combine months if the first page has fewer than 20 items
      if (paginated_data[0] && paginated_data[0].reduce((sum, page) => sum + page.trades.length, 0) < 20 && paginated_data[1]) {
        // Merge current page with the next page if the first page has fewer than 20 items
        paginated_data[0] = [...paginated_data[0], ...paginated_data[1]];
        // Remove the second page after merging
        paginated_data.splice(1, 1);
      }

      setSavedtradedatavalues(paginated_data[0] || []); // Set data for the first page
      setPaginationData(paginated_data); // Store all pages for future navigation

// // Example pagination handling (if needed):
// const handlePageChange = (pageNumber) => {
//   const newData = paginatedData[pageNumber] || [];
//   setSavedtradedatavalues(newData);
// };


     


      }

      if (savedresponse.status === 404) {
        toast.error("No data found in history.", { position: "top-right" });
        setLoading(false);
        return;
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  
  // const sortedsavedData = paginationData.map((subArray) => {
  //   return [...subArray].sort((a, b) => {
    
  //     const dateComparison = new Date(b.date) - new Date(a.date);
  
  //     // If dates are the same, compare by time in descending order
  //     if (dateComparison === 0) {
  //       return (
  //         new Date(`1970-01-01T${b.time}`) - new Date(`1970-01-01T${a.time}`)
  //       );
  //     }
  
  //     return dateComparison;
  //   });
  // });
  


  // const sortedsavedData = paginationData.map((subArray) => {
  //   return [...subArray].sort((a, b) => {
  //     const dateComparison = new Date(b.date) - new Date(a.date);
  
  //     // If dates are the same, compare by time in descending order
  //     if (dateComparison === 0) {
  //       return (
  //         new Date(`1970-01-01T${b.time}`) - new Date(`1970-01-01T${a.time}`)
  //       );
  //     }
  
  //     return dateComparison;
  //   });
  // });
  
  const sortedsavedData = paginationData ;
  console.log("Sorted Paginated Data:", sortedsavedData);

  const flattenedData = sortedsavedData.map(arr => arr.flatMap(item => item.trades));
  // const flattenedData = sortedsavedData.flat().map((item) => item.trades).flat();

  // Now flattenedData contains all the trades
  // const currentPageData = flattenedData || [];
// Optionally, apply any filter logic here before setting `currentPageData`

console.log("Flattened Data:", flattenedData);
// console.log("currentPageData:", currentPageData);

  // const finalsortedData = sorteddata2.map
  

  useEffect(() => {
    savedtradedata();
  }, [refetchData]);

  useEffect(() => {
    if (refetchData) {
      setRefetchData(false);
    }
  }, [refetchData]);

  // const [currentPage, setCurrentPage] = useState(0); // Track the current page (array index)


  
  
  // const currentPageData = flattenedData[currentPage] || [];
  // console.log("currentPageData" , currentPageData)



  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const [filteredEventsbysearch, setFilteredEventsbysearch] = useState([]);
  useEffect(() => {
    if (searchTerm) {
      const filteredData = flattenedData.flat().filter((event) =>
        event.event &&
        event.event.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredEventsbysearch(filteredData);
    } else {
      setFilteredEventsbysearch([]); // Reset when search is cleared
    }
  }, [searchTerm]);
  // <-----flag to check whether a filter is applied or not??------>
  const isFiltered = 
  selectedCountry.length > 0 || 
  selectedCurrency.length > 0 || 
  filteredEventsbysearch.length > 0 || 
  tradelogchange.length > 0 ||
  dateRange !== null; 

  console.log("isFiltered" , isFiltered)
  console.log("selectedCountry.length" , selectedCountry.length , selectedCurrency.length , filteredEventsbysearch.length , tradelogchange.length)
  // Filter the data based on selected criteria
  // const filteredData = isFiltered ? ( flattenedData.flat().filter((item) =>
  // (selectedCountry.length > 0 ? selectedCountry.includes(item.country) : true) &&
  // (selectedCurrency.length > 0 ? selectedCurrency.includes(item.currency) : true) &&
  // (filteredEventsbysearch.length > 0 
  //       ? filteredEventsbysearch.some(filteredItem => filteredItem.event === item.event) 
  //     : true) &&
  //   (tradelogchange.length > 0 ? tradelogchange.includes(item.trade) : true)
  // )
  // ) : flattenedData
  const filteredData = isFiltered
  ? flattenedData.flat().filter((item) => {
      const passesCountryFilter = selectedCountry.length > 0 ? selectedCountry.includes(item.country) : true;
      const passesCurrencyFilter = selectedCurrency.length > 0 ? selectedCurrency.includes(item.currency) : true;
      const passesEventFilter = filteredEventsbysearch.length > 0
        ? filteredEventsbysearch.some(filteredItem => filteredItem.event === item.event)
        : true;
      const passesTradeFilter = tradelogchange.length > 0 ? tradelogchange.includes(item.trade) : true;
      const passesDateFilter = dateRange
        ? new Date(item.date) >= new Date(dateRange[0]) && new Date(item.date) <= new Date(dateRange[1])
        : true;

      return passesCountryFilter && passesCurrencyFilter && passesEventFilter && passesTradeFilter && passesDateFilter;
    })
  : flattenedData.flat(); // Ensure `flattenedData.flat()` is used for unfiltered data

console.log("Filtered Data:", filteredData);

// Paginate the filtered data, 100 items per page
const paginatedFilteredData = [];
if (isFiltered && filteredData.length > 0) {
  for (let i = 0; i < filteredData.length; i += 100) {
    paginatedFilteredData.push(filteredData.slice(i, i + 100));
  }
}

// console.log("Paginated Filtered Data:", paginatedFilteredData);

//   console.log("filtereddata" , filteredData)

  // Paginate the filtered data, 100 items per page
  // const paginatedFilteredData = [];
  // if (isFiltered) {
  //   for (let i = 0; i < filteredData.length; i += 100) {
  //     paginatedFilteredData.push(filteredData.slice(i, i + 100));
  //   }
  // }

  // <-----chcek if filter is already applied or not??----->
  const currentPageData = isFiltered ? paginatedFilteredData[currentPage] || []: flattenedData[currentPage] || [];
  console.log("currentPageData" , currentPageData);
  
  


  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 6;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };



  // const handleNext = () => {
  //   if (currentPage < sortedsavedData.length - 1) {
  //     setCurrentPage((prev) => prev + 1);
  //   }
  // };
  const handleNext = () => {
    if (isFiltered) {
      if (currentPage < paginatedFilteredData.length - 1) {
        setCurrentPage((prev) => prev + 1);
      }
    } else {
      if (currentPage < sortedsavedData.length - 1) {
        setCurrentPage((prev) => prev + 1);
      }
    }
  };
  

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };





  const trade_log = [
    { value: "Profit", label: "Profit" },
    { value: "Loss", label: "Loss" },
    { value: "N.A", label: "N.A" },
  ];

  const prediction = [
    { value: "Correct", label: "Correct" },
    { value: "Wrong", label: "Wrong" },
    { value: "N.A", label: "N.A" },
  ];

  const customStyles = {
    option: (provided, { data, isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused
        ? "#f0f0f0"
        : isSelected
        ? "#F6F6F6"
        : "#F6F6F6",
      color:
        data.value === "Correct"
          ? "green"
          : data.value === "Wrong"
          ? "red"
          : data.value === "N.A"
          ? "#c78c06"
          : "black",
    }),
    control: (provided) => ({
      ...provided,
      minWidth: "145px",
      backgroundColor: "#F6F6F6",
    }),
    singleValue: (provided, { data }) => ({
      ...provided,
      color:
        data.value === "Correct"
          ? "green"
          : data.value === "Wrong"
          ? "red"
          : data.value === "N.A"
          ? "#c78c06"
          : "black",
    }),
  };
  
  const customStyles1 = {
    option: (provided, { data, isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused
        ? "#f0f0f0"
        : isSelected
        ? "#F6F6F6"
        : "#F6F6F6",
      color:
        data.value === "Profit"
          ? "green"
          : data.value === "Loss"
          ? "red"
          : data.value === "N.A"
          ? "#c78c06"
          : "black",
      
    }),
    control: (provided) => ({
      ...provided,
      minWidth: "145px",
      backgroundColor: "#F6F6F6",
    }),
    singleValue: (provided, { data }) => ({
      ...provided,
      color:
        data.value === "Profit"
          ? "green"
          : data.value === "Loss"
          ? "red"
          : data.value === "N.A"
          ? "#c78c06"
          : "black",
    }),
  };

  const handleSelectChange = (tradeid, fieldName,selectedValue,fullTradeData) => {
    const value = selectedValue?.value || selectedValue || null;
   

    setSavedtradedatavalues((prevState) => {
      const existingItemIndex = prevState.findIndex(
        (selectedItem) => selectedItem._id === tradeid // Ensure you are checking the correct key
      );

      // <-- matches-->
      if (existingItemIndex !== -1) {
        const updatedItem = {
          ...prevState[existingItemIndex],
          [fieldName]: value, 
          date: fullTradeData.date,
          time: fullTradeData.time,
          country: fullTradeData.country,
          event: fullTradeData.event,
          currency: fullTradeData.currency,
        };
      // <compare and check if any new chnge have been made----></compare>
        if ( JSON.stringify(prevState[existingItemIndex]) !== JSON.stringify(updatedItem) ) {
          set_SendUpdatedItemToBackend((prevItems) => {
            const itemExists = prevItems.find(
              (item) => item._id === updatedItem._id
            );

            return itemExists
              ? prevItems.map((item) =>
                  item._id === updatedItem._id ? updatedItem : item
                )
              : [...prevItems, updatedItem];
          });
        }

        const updatedState = prevState.map((selectedItem, index) => {
          return index === existingItemIndex ? updatedItem : selectedItem;
        });

        return updatedState;
      } 
      
      else {
        const newItem = {
          _id: tradeid,
          [fieldName]: value,
          date: fullTradeData.date,
          time: fullTradeData.time,
          country: fullTradeData.country,
          event: fullTradeData.event,
          currency: fullTradeData.currency,
        };

        return [...prevState, newItem];
      }
    });
  };

  useEffect(() => {}, [savedtradedatavalues]);

  const handleInputChange = (tradeid, fieldName, value, fullTradeData) => {
    console.log("hlo inputs", tradeid, fieldName, value);
    console.log("fullTradeData", fullTradeData);
    setSavedtradedatavalues((prevState) => ({
      ...prevState,

      [tradeid]: {
        ...prevState[tradeid],
        [fieldName]: value,
        date: fullTradeData.date,
        time: fullTradeData.time,
        country: fullTradeData.country,
        event: fullTradeData.event,
        currency: fullTradeData.currency,
      },
    }));
  };

  const updateTradeData = async () => {
    try {
      setLoading1(true);
      const userid = localStorage.getItem("userregisterId");

      if (savedtradedatavalues.length === 0) {
        console.error("No saved trade data to update.");
        return;
      }

      const updatePromises = sendUpdatedItemToBackend.map(async (trade) => {
        const tradeid = trade._id;

        const apiUrl = `${process.env.REACT_APP_URL}/user/updatesavedtradingdata/${userid}/${tradeid}/${trade.date}`;
        const updateFields = {
          prediction: trade.prediction,
          trade_log: trade.trade_log,
          trade_amount: trade.trade_amount,
          trade_time: trade.trade_time,
          trade_comment: trade.trade_comment,
        };

        try {
          const response = await axios.put(apiUrl, updateFields);
          if (
            response.data.message === "Trade updated successfully" &&
            response.status === 200
          ) {
            return true;
          }
        } catch (error) {
          console.error("Error updating trade:", error);
        }
        return false;
      });

      const results = await Promise.all(updatePromises);
      const tradesUpdated = results.filter((result) => result).length;

      if (tradesUpdated > 0) {
        toast.success("Trades updated successfully!", {
          position: "top-right",
        });
        setTimeout(() => {
          toast.info("Reloading page .....", { position: "top-right" });
          window.location.reload();
        }, 1000);
      } else {
        toast.info("No trades were updated.", { position: "top-right" });
      }

      savedtradedata();
      setRefetchData(true);
    } catch (error) {
      console.error("Error updating trades:", error);
    } finally {
      setLoading1(false);
    }
  };

 
  const handleCountryChange = (values) => {

    setSelectedCountry(values);
  };
  
  const handleCurrencyChange = (values) => {
  
    setSelectedCurrency(values);
  };
  
  
  
  const handleTradelogChange = (values)=>{
  setTradelogchange(values)
  }


  const handleDateChange = async(dates, dateStrings) => {
    // setLoading(true);
    const userid = localStorage.getItem("userregisterId")
    // const tokenfromls = localStorage.getItem("token");
    // const h = { authorization: tokenfromls };
    const [startDate, endDate] = dateStrings;
    if (!dates) {
      setDateRange(null); 
      savedtradedata();
      // setLoading(false);
      return; 
    }
    try{
      const response = await axios.post(`${process.env.REACT_APP_URL}/user/datepicker_fromsavedtrade` ,  { startDate, endDate  , userid} , 
          // { headers: h }
        );
     const latestDocument = response.data
     let allTrades = [];
  
     latestDocument.forEach((doc) => {
       allTrades = allTrades.concat(doc.trades);
     });
    
    const pageSize = 20; 
    const paginatedData = [];

    // for (let i = 0; i < allTrades.length; i += pageSize) {
    //   paginatedData.push(allTrades.slice(i, i + pageSize));
    // }

    for (let i = 0; i < filteredData.length; i += 100) {
      paginatedFilteredData.push(filteredData.slice(i, i + 100));
    }

    // Set the paginated data and trigger sorting effect
    // setPaginationData(paginatedData);
    //  setLoading(false);
    }
      catch(error) {
       
        // setLoading(false);
       
        console.error("Error fetching data:", error);
      };
      setDateRange(dates);
  };
  
 
  // const handleTrade_logChange = (tradeid, val) => {
  //   console.log("tradeid" , tradeid)
  //   console.log("event.target.value" , val)
  //   settradeLog((prev) => ({
  //     ...prev,
  //     // [tradeid]: val, 
  //     [tradeid]: {
  //       ...prev[tradeid], // Retain the previous values
  //       trade_log: val, // Update trade_log for the given tradeid
  //     },
  //   }));
  // };
  // const handlePredictionChange = (tradeid, val) => {
  //   // console.log("tradeid" , tradeid)
  //   // console.log("event.target.value" , val)
  //   setPredictionChange((prev) => ({
  //     ...prev,
  //     // [tradeid]: val, // Update the specific tradeid
  //     [tradeid]: {
  //       ...prev[tradeid], // Retain the previous values
  //       prediction: val, // Update trade_log for the given tradeid
  //     },
  //   }));
  // };

  // const handleCommentsChange = (tradeid, val) => {
  //   // console.log("tradeid" , tradeid)
  //   // console.log("event.target.value" , val)
  //   settrade_comment((prev) => ({
  //     ...prev,
  //     // [tradeid]: val, // Update the specific tradeid
  //     [tradeid]: {
  //       ...prev[tradeid], // Retain the previous values
  //       tradecomment: val, // Update trade_log for the given tradeid
  //     },
  //   }));
  // };
  const handlePredictionChange = (tradeid, val , date) => {
    setTempChanges((prev) => ({
      ...prev,
      [date] : {
        ...prev[date], 
      [tradeid]: {
        ...prev[date]?.[tradeid],
        prediction: val, 
        //  prediction update bro
      }}
    }));
  };
  const handleTrade_logChange = (tradeid, val , date) => {
    setTempChanges((prev) => ({
      ...prev,
      [date] : {
        ...prev[date], 
      [tradeid]: {
        ...prev[date]?.[tradeid], 
        trade_log: val, 
      }}
    }));
  };
  
  const handleCommentsChange = (tradeid, val , date) => {
    setTempChanges((prev) => ({
      ...prev,
      [date] : {
        ...prev[date], 
      [tradeid]: {
        ...prev[date]?.[tradeid], 
        trade_comment: val, 
      }}
    }));
  };
  
  const handleTradetimeChange = (tradeid, val , date) => {
    // console.log("tradeid" , tradeid)
    // console.log("event.target.value" , val)
    setTempChanges((prev) => ({
      ...prev,
      [date] : {
        ...prev[date], 
      // [tradeid]: val, // Update the specific tradeid
      [tradeid]: {
        ...prev[date]?.[tradeid], // Retain the previous values
        trade_time: val, // Update trade_log for the given tradeid
      }}
    }));
  };


  const handleTradeAmountChange = (tradeid, val , date) => {
    // console.log("tradeid" , tradeid)
    // console.log("event.target.value" , val)
    setTempChanges((prev) => ({
      ...prev,
      // [tradeid]: val, // Update the specific tradeid
      [date] : {
        ...prev[date], 
      [tradeid]: {
        ...prev[date]?.[tradeid], // Retain the previous values
        trade_amount: val, // Update trade_log for the given tradeid
      }}
    }));
  };



  const [maintradedata, setmaintradedata] = useState({});
  useEffect(() => {
    // Transform `maindata` array into an object where keys are `_id`s
    const dataObject = currentPageData.reduce((acc, item) => {
      acc[item._id] = item;
      return acc;
    }, {});

    // Update the state once with the full transformed object
    setmaintradedata(dataObject);
  }, []); // Re-run this only if `maindata` changes

  const [tempChanges, setTempChanges] = useState({});
  const sendSelected_tradevalues2 = async () => {
    try {
      const userid = localStorage.getItem("userregisterId");
  
      // Now, you send the data as it is from tempChanges
      // tempChanges will already have all the tradeid entries with their prediction and comment
  
      console.log("tempChanges", tempChanges, userid);
  
      // Send the data directly as allItems (no need for additional loop)
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/user/updatesavedtradingdata`,
        { allItems: tempChanges, userid }
      );
  
      toast.success("Data has been saved successfully!", {
        position: "top-right",
      });
  
    setTimeout(() => {
      toast.info("Reloading page .....", { position: "top-right" });
      window.location.reload();
    }, 1000);
      savedtradedata();
    } catch (error) {
      console.error("Error saving data", error);
    }
  };
  
  
  
  return (
    <>
      {Loading ? (
        <div className="flex items-center justify-center  mt-10 w-full">
          <RingLoader
            height="80"
            width="80"
            color="#94A3B8"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            className=" flex items-center justify-center  mt-10 w-[100%]"
          />
        </div>
      ) : (
        <>
          <UserNavbar />
          <div className="max-w-[100%] mx-[4%] ">
            {currentPageData.length === 0 ? (
              <div
                className={`${styles.data_missing} m-2 mt-[40%] sm:mt-[40%] md:mt-[16%] lg:mt-[13%] xl:mt-[8%] text-center font-semibold text-xl `}
              >
                Sorry!! you haven't saved any data yet .
              </div>
            ) : (
              <>
              
                 
                <h1 className={` ${styles.historyheading} font-bold fixed w-full text-[#151841]  my-2 ml-0 mr-2 text-[24px] sm:text-[24px] md:text-[32px] mt-[26%] sm:mt-[26%] md:mt-[12%] lg:mt-[8%] xl:mt-[5%] flex items-center`}>
                {/* <button onClick={() => navigate('/user/economicdata')} className="btn-back">
                Back
              </button> */}
                {/* <button onClick={() => history.goBack()}>Back</button> */}
                <IoArrowBack className="mr-2 cursor-pointer " onClick={() => navigate('/user/economicdata')} title="Back Button"/>  History
                </h1>

                <div
                  className={` ${styles.saved_haeder} fixed w-[50%] sm:w-[50%]  md:w-[70%] lg:w-[90%] xl:w-[100%] mt-[16%] sm:mt-[16%] md:mt-[16%] lg:mt-[12%] xl:mt-[8%] flex-col sm:flex-col md:flex-col  lg:flex-col xl:flex-row flex md:ml-[-13px] xl:ml-0 `}
                >
                  <div
                    className={`${styles.saved_haeder1} flex justify-center items-start sm:items-start md:items-start lg:items-start xl:items-center  sm:flex-col md:flex-col flex-col lg:flex-col xl:flex-row`}
                  >
                    <div className="pl-[0px] sm:pl-[0px] md:pl-[12px] lg:pl-[14px] xl:pl-0">
                      <div className="relative block sm:block md:inline-block lg:inline-block xl:inline-block pr-0 sm:pr-3 md:pr-3 lg:pr-3 xl:pr-3  mb-4 sm:mb-4 md:mb-4 lg:mb-0 xl:mb-0 ">
                        
                    <Space style={{ width: "100%" }} direction="vertical" className="z-[999] border-[#170E32] ">
                        <Select
                          mode="multiple"
                          allowClear
                          style={{
                            width: "270px",
                            height: "46px",
                            maxHeight: "46px",
                            backgroundColor: "#F6F6F6", // Grey background
                            border: "1px solid #170E32", // Light grey border
                            borderRadius: "4px",
                          }}
                          placeholder={<span style={{ color: "#170E32", fontSize: "16px" }}>Country</span>}
                          onChange={handleCountryChange}
                          getPopupContainer={(triggerNode) => document.body}
                          className="z-[999] custom-select overflow-visible"
                          dropdownStyle={{
                            maxHeight: "800px",
                            overflowY: "auto", 
                          }}
                          suffixIcon={
                            <DownOutlined
                              style={{
                                fontSize: "14px",
                                color: "#170E32", 
                              }}
                            />
                          }
                          maxTagCount={1} // Only show one country in the select box
                          maxTagTextLength={20} // Adjust max length if necessary
                        >
                          <Option value="AU">Australia (AU)</Option>
                          <Option value="CA">Canada (CA)</Option>
                          <Option value="EU">Europe (EU)</Option>
                          <Option value="FR">France (FR)</Option>
                          <Option value="DE">Germany (DE)</Option>
                        
                          
                          <Option value="IT">Italy (IT)</Option>
                          <Option value="JP">Japan (JP)</Option>
                          <Option value="NZ">New Zealand (NZ)</Option>
                          <Option value="CH">Switzerland (CH)</Option>
                          <Option value="UK">United Kingdom (UK)</Option>
                          <Option value="US">United States (US)</Option>
                        
                          
                        
                          
                          
                          
                        
                        
                        
                        </Select>
                         </Space>
                      </div>

                      <div className="relative block sm:block md:inline-block lg:inline-block xl:inline-block px-0  mb-4 sm:mb-4 md:mb-4 lg:mb-0 xl:mb-0 ">
                        
                          <Space style={{ width: "100%" }} direction="vertical"  className="z-[999] border-[#170E32] ">
                          <Select
                            mode="multiple"
                            allowClear
                            style={{
                              width: "270px",
                              height: "46px",
                              maxHeight: "46px",
                              backgroundColor: "#F6F6F6", // Grey background
                              border: "1px solid #170E32", // Light grey border
                              borderRadius: "4px",
                            }}
                            placeholder={
                              <span style={{ color: "#170E32", fontSize: "16px" }}>Currency</span>
                            }
                            onChange={handleCurrencyChange}
                         
                            getPopupContainer={(triggerNode) => document.body}
                            className="z-[999] custom-select overflow-visible"
                            dropdownStyle={{
                              maxHeight: "800px",
                              overflowY: "auto", 
                            }}
                            suffixIcon={
                              <DownOutlined
                                style={{
                                  fontSize: "14px",
                                  color: "#170E32", 
                                }}
                              />
                            }
                            maxTagCount={3}
                            maxTagTextLength={20} 
                          >
                            <Option value="AUD">AUD</Option>
                            <Option value="CAD">CAD</Option>
                            <Option value="CHF">CHF</Option>
                            <Option value="EUR">EUR</Option>
                            <Option value="JPY">JPY</Option>
                            <Option value="GBP">GBP</Option>
                            <Option value="NZD">NZD</Option>
                            <Option value="USD">USD</Option>
                           
                            
                          </Select>
                        </Space>
                      </div>
                    </div>

                    <div className={`flex justify-center items-center mt-0 md:mt-[6px] lg:mt-[6px] xl:mt-0`} >
                      <div className="relative block sm:block md:inline-block lg:inline-block xl:inline-block px-0 sm:px-3 md:px-3 lg:px-3 xl:px-3  mb-4 sm:mb-4 md:mb-4 lg:mb-0 xl:mb-0 ">
                        
                          <Space style={{ width: "100%" }} direction="vertical"  className="z-[999] border-[#170E32] ">
                          <Select
                            mode="multiple"
                            allowClear
                            style={{
                           
                              height: "46px",
                              maxHeight: "46px",
                              backgroundColor: "#F6F6F6", // Grey background
                              border: "1px solid #170E32", // Light grey border
                              borderRadius: "4px",
                            }}
                            placeholder={
                              <span style={{ color: "#170E32", fontSize: "16px" }}>Trade</span>
                            }
                            onChange={handleTradelogChange}
                          
                            getPopupContainer={(triggerNode) => document.body}
                            className="w-[190px] sm:w-[190px] md:w-[270px] lg:w-[270px] xl:w-[270px] z-[999] custom-select overflow-visible"
                            dropdownStyle={{
                              maxHeight: "800px",
                              overflowY: "auto", 
                            }}
                            suffixIcon={
                              <DownOutlined
                                style={{
                                  fontSize: "14px",
                                  color: "#170E32", // Change icon color here
                                }}
                              />
                            }
                            maxTagCount={3} // Only show one country in the select box
                            maxTagTextLength={20} // Adjust max length if necessary
                          >
                            <Option value="Yes">YES</Option>
                            <Option value="No">NO</Option>
                          
                          </Select>
                        </Space>
                      </div>
                      <div
                        className={` ${styles.search} w-[280px] h-[46px]  relative block sm:block md:inline-block lg:inline-block xl:inline-block px-3 sm:px-3 md:px-3 lg:px-2 xl:px-2  `}
                      >
                        <input
                          type="text"
                          placeholder="Search Events"
                          className="rounded-[4px]  placeholder-[#170E32]  pl-4 bg-[#F6F6F6] h-[48px] w-[50%] sm:w-[50%] md:w-[76%] lg:w-full xl:w-full p-1 placeholder:text-left  border-[#170E32] border  focus:outline-none  "
                          value={searchTerm}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className={` ${styles.saved_haeder3} range-picker items-center  text-center flex mt-[-4px] sm:mt-[-4px] md:mt-[2px] ml-[0%] sm:ml-[0%] md:ml-[0%] lg:ml-0 xl:ml-0 pl-0 sm:pl-0 md:pl-[20px] pr-4  `}
                    style={{ height: "56px" }}
                  >
                      <Space direction="vertical" size={16}>
                       
                        <RangePicker 
                        value={dateRange} 
                        onChange={(dates, dateStrings) => handleDateChange(dates, dateStrings)} 
                            className="text-[16px] h-[48px] w-[332px] sm:w-[332px] md:w-[270px] lg:w-[270px] xl:w-[270px]   border-[#170E32] border active:outline-none bg-[#F6F6F6] focus:outline-none  rounded-md placeholder-[#170E32] custom-rangepicker "
                      />
                      </Space>
                  </div>
                </div>

                <div
                  className={`${styles.saved_table} fixed  w-[90%] overflow-auto mt-[100%] sm:mt-[100%] md:mt-[44%] lg:mt-[27%] xl:mt-[12%]`}
                >
                  <div className={`flex justify-end items-center mb-5 mt-[1%] sm:mt-[1%] md:mt-0`}>
                    <h1
                      className="cursor-pointer text-[16px] font-semibold bg-[#151841] text-white rounded-[8px] py-2 px-6"
                      title="Click here to save your trade"
                      onClick={sendSelected_tradevalues2}
                    >
                      Save Changes
                    </h1>
                    
                  </div>
                  <div className="overflow-auto mb-4">
                    <div className="overflow-x-auto overflow-y-auto  max-h-[40vh] sm:max-h-[40vh] md:max-h-[50vh] lg:max-h-[56vh] xl:max-h-[64vh]">
                      <>
                        <table
                          className={` ${styles.tableuser} min-w-full  rounded overflow-x-scroll m-auto text-[16px] font-semibold`}
                        >
                          <thead
                            key={2}
                            className="bg-[#d0dcf4]   key={1} text-black  sticky top-0 z-10 text-[16px] font-semibold border-b-[12px] border-solid border-[#F6F6F6] rounded-[8px]"
                          >
                            <tr className="h-[96px]">
                              <th className="sticky top-0 py-2 px-4">S. No.</th>
                              <th className=" sticky top-0 py-2 px-4">Date</th>
                              <th className=" sticky top-0 py-2 px-4">
                                Time(GMT)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Country
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Currency
                              </th>
                              <th className="sticky top-0 py-2 px-4">Event</th>
                              <th className="sticky top-0 py-2 px-4">Trade</th>
                              <th className="sticky top-0 py-2 px-4">
                                Trade Grade
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Investing.com Var name
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Trading view Var name
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Trading Economics Var name
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Movement (Currency)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Movement (Correlation)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Flat (Currency)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Flat (Correlation)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Comments
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Prediction
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Trade log(profit/loss)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Trade Amount
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Trade in time(seconds)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Trade comment
                              </th>
                            </tr>
                          </thead>

                          <tbody className="bg-[#F6F6F6]  z-0">
                            {currentPageData
                              // .filter(
                              //   (item) =>
                              //     (selectedCountry.length > 0
                              //       ? selectedCountry.includes(item.country)
                              //       : true) &&
                              //     (selectedCurrency.length > 0
                              //       ? selectedCurrency.includes(item.currency)
                              //       : true) &&
                              //     (filteredEventsbysearch.length > 0
                              //       ? filteredEventsbysearch.some(
                              //           (filteredItem) =>
                              //             filteredItem.event === item.event
                              //         )
                              //       : true) &&
                              //     (tradelogchange.length > 0
                              //       ? tradelogchange.includes(item.trade)
                              //       : true)
                              // )

                              .map((item, index) => {
                                const unfiltereddataLength = !isFiltered? flattenedData.slice(0, currentPage).reduce((sum, page) => sum + page.length, 0): 0;
                                console.log("unfiltereddataLength" . unfiltereddataLength)
                                return (
                                  <>
                                    <tr
                                      key={item._id}
                                      className="cursor-pointer  text-center bg-white  border-y-[16px] border-solid border-[#F6F6F6] "
                                    >
                                      <td className="py-1 px-2 min-w-[80px]">
                                        {/* {index + 1}. */}
                                        {index + 1 + (isFiltered ? currentPage * 100 : unfiltereddataLength)}.
                                      </td>
                                      <td className="py-1 px-2">{item.date}</td>
                                     
                                      <td className="py-1 px-2">{item.time.slice(0, 5)}</td>
                                      <td className="py-1 px-2">
                                        {item.country}
                                      </td>
                                      <td className="py-1 px-2">
                                        {item.currency}
                                      </td>
                                      <td className="py-1 px-2 ml-[-5px] text-black font-bold min-w-[250px]">
                                        {item.event}
                                      </td>

                                      <td className="py-1 px-2">
                                        {item.trade}
                                      </td>
                                      <td className="py-1 px-2">
                                        {item.trade_grade}
                                      </td>
                                      <td className="py-1 px-2 min-w-[145px]">
                                        {item.investing_name}
                                      </td>
                                      <td className="py-1 px-2 min-w-[145px]">
                                        {item.trading_view}
                                      </td>
                                      <td className="py-1 px-2 min-w-[145px]">
                                        {item.trading_economics}
                                      </td>
                                      <td className="py-1 px-2">
                                        {item.movement_currency}
                                      </td>
                                      <td className="py-1 px-2">
                                        {item.movement_correlation}
                                      </td>
                                      <td className="py-1 px-2">
                                        {item.flat_currency}
                                      </td>
                                      <td className="py-1 px-2">
                                        {item.flat_correlation}
                                      </td>
                                      <td className="py-1 px-2 min-w-[360px]">
                                        {item.comments}
                                      </td>

                                      <td className="py-1 px-2 min-w-[165px] ">
                                        <Selects
                                          options={prediction}
                                          className="bg-[#F6F6F6]"
                                          // value={prediction.find(
                                          //   (option) =>
                                          //     option.value === item.prediction
                                          // )}
                                          value={prediction.find((option) => option.value === tempChanges[item.date]?.[item._id]?.prediction) || prediction.find(
                                            (option) => option.value === item.prediction
                                          )}
                                         
                                          onChange={(option) =>
                                            handlePredictionChange(
                                              item._id,
                                              option.value , item.date
                                            )
                                          }
                                          styles={customStyles}
                                        />
                                      </td>
                                      <td className="py-1 px-2 min-w-[165px]">
                                        <Selects
                                          options={trade_log}
                                          // value={trade_log.find(
                                          //   (option) =>
                                          //     option.value === item.trade_log
                                          // )}
                                          value={trade_log.find((option) => option.value === tempChanges[item.date]?.[item._id]?.trade_log) || trade_log.find(
                                            (option) => option.value === item.trade_log
                                          )}
                                         
                                          onChange={(option) =>
                                            handleTrade_logChange(
                                              item._id,
                                              option.value , item.date
                                            )
                                          }
                                          styles={customStyles1}
                                        />
                                      </td>

                                      <td className="py-1 px-2 min-w-[165px]">
                                        <input
                                          type="text"
                                          name="trade_amount"
                                          id="trade_amount"
                                          className="focus:border-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[4px] block w-full p-2.5"
                                          placeholder=""
                                          required
                                          // value={
                                          //   tempChanges[item.date]?.[item._id]?.trade_amount ??
                                          //   item.trade_amount
                                          // }
                                          
                                          value={tempChanges[item.date]?.[item._id]?.trade_amount ?? item.trade_amount}
                                          // value={item.trade_amount}
                                          // onChange={(e) => {
                                          //   const isLoss = trade_log.find((option) =>option.value === item.trade_log)?.value === "Loss";
                                          //   const value = e.target.value;

                                           
                                          //   let numericValue = value.replace( /[^0-9.-]/g, "");
                                          
                                          //   if ((numericValue.match(/\./g) || []).length > 1) {
                                          //     numericValue = numericValue.slice(0,numericValue.lastIndexOf("."));
                                          //   }
                                          
                                          //   if (isLoss && numericValue !== "" && !numericValue.startsWith("-")) {
                                            
                                          //     numericValue=(Math.abs(Number(numericValue)))*-1
                                             
                                          // }
                                         
                                          // // onInput={(numericValue) =>
                                          // //   handleCommentsChange(item._id, numericValue)
                                          // // }
                                          //   handleSelectChange(item._id, "trade_amount", numericValue, item);
                                          // }}

                                          onChange={(event) => handleTradeAmountChange(item._id, event.target.value , item.date)}
                                        />
                                      </td>

                                      <td className="py-1 px-2 min-w-[165px]">
                                        <input
                                          type="text"
                                          name="trade_time"
                                          id="trade_time"
                                          className="focus:border-0 bg-gray-50 border border-gray-300 rounded-[4px]
                                        text-gray-900 text-sm  block w-full p-2.5  "
                                          placeholder=""
                                          required
                                          value={tempChanges[item.date]?.[item._id]?.trade_time ?? item.trade_time}
                                          // value={item.trade_time}
                                          // onChange={(e) =>
                                          //   handleSelectChange(
                                          //     item._id,
                                          //     "trade_time",
                                          //     e.target.value,
                                          //     item
                                          //   )
                                          // }\
                                          onChange={(event) => handleTradetimeChange(item._id, event.target.value , item.date)}
                                          // onInput={(event) =>
                                          //   handleTradetimeChange(item._id, event)
                                          // }
                                        />
                                      </td>

                                      <td className="py-1 px-2 min-w-[270px]">
                                        <input
                                          type="text"
                                          name="trade_comment"
                                          id="trade_comment"
                                          className="focus:border-0 bg-gray-50 border border-gray-300
                                        text-gray-900 text-sm rounded-[4px]  block w-full p-2.5 "
                                          placeholder=""
                                          required
                                          value={tempChanges[item.date]?.[item._id]?.trade_comment ?? item.trade_comment}
                                          // value={item.trade_comment}
                                          title={item.trade_comment}
                                          // 
                                          onChange={(event) => handleCommentsChange(item._id, event.target.value , item.date)}
                                        />
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                          </tbody>
                        </table>

                        {Loading1 ? (
                          <div className="fixed bottom-0 right-0 mb-2 flex items-end justify-end   w-full">
                            <LineWave
                              height="60"
                              width="60"
                              color="#111827"
                              ariaLabel="bars-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                              className=" w-10 h-10 flex items-center justify-center  mt-10 "
                            />
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </>



                    

                    </div>
                  </div>
                  <div className="flex justify-between mt-4">
                     
                      <div className="flex justify-center items-center cursor-pointer" onClick={handlePrevious}
                        disabled={currentPage === 0}>
                     Prev. <FaLongArrowAltLeft  />
                        </div>
                     
                     <div className="flex justify-center items-center cursor-pointer" onClick={handleNext}
                        disabled={currentPage === sortedsavedData.length - 1}>
                     Next <FaLongArrowAltRight />
                        </div>
                    </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Savedtrade;
