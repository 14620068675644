// import React, { useState, useEffect } from "react";
// // import Chart from "react-apexcharts";
// import axios from "axios";
// import ReactApexChart from "react-apexcharts";
// const LiveCharts = () => {
//   const [chartData, setChartData] = useState({
//     series: [],
//     options: {
//       chart: {
//         type: "line",
//         zoom: { enabled: true },
//       },
//       xaxis: {
//         categories: [], // Dates or X-axis values
//       },
//       title: {
//         text: "Line Chart",
//         align: "center",
//       },
//     },
//   });

// //   useEffect(() => {
// //     const fetchData = async () => {
// //       try {
// //         const response = await axios.get("https://api.example.com/data"); // Replace with your API URL
// //         const apiData = response.data;

// //         // Process data for the chart
// //         const categories = apiData.map((item) => item.date); // Example: Date field for X-axis
// //         const values = apiData.map((item) => item.value); // Example: Value field for Y-axis

// //         setChartData((prevState) => ({
// //           ...prevState,
// //           series: [{ name: "Value", data: values }],
// //           options: {
// //             ...prevState.options,
// //             xaxis: { categories },
// //           },
// //         }));
// //       } catch (error) {
// //         console.error("Error fetching chart data:", error);
// //       }
// //     };

// //     fetchData();
// //   }, []);




  
// const [modelsData, setModelsData] = useState([]); 
//     const fetchTimeseriesData = async () => {
//       try {
//         // const urlParams = new URLSearchParams(window.location.search);
//         // const eventName = decodeURIComponent(urlParams.get('eventName'));
//         // const eventcountry = decodeURIComponent(urlParams.get('eventcountry'));
//       const eventandcountrydata = localStorage.getItem("eventnameandcountry:");
//       console.log("eventandcountrydata" , eventandcountrydata)
//       const eventDataObj = JSON.parse(eventandcountrydata);

//       const { eventName, eventcountry } = eventDataObj;
//         let graphdataarray = localStorage.getItem("4GraphDataobjects");
  
//         if (graphdataarray) {
//           graphdataarray = JSON.parse(graphdataarray);
//           // console.log("graphdataarray" , graphdataarray)
//           if (graphdataarray.length > 0) {
  
//             const current_Item = graphdataarray.find(
//               (item) => item.event === eventName && item.country === eventcountry
//             );
//             // const lastItem = graphdataarray[graphdataarray.length - 1];
//             // console.log("current_Item" , current_Item)
           
  
//             const event = current_Item.event;
//             // seteventname(event); 
//             const country = current_Item.country;
//             // setcountryname(country);
//             let extractedDatesAndTimes2 = [];
  
//             current_Item.four_graph_values.forEach((graphItem) => {
//               const { date, time } = graphItem;
//               // <------if same date and time is not available then push the data ------------>
//               if (
//                 !extractedDatesAndTimes2.some(
//                   (item) => item.date === date && item.time === time
//                 )
//               ) {
//                 extractedDatesAndTimes2.push({ date, time, country });
//               }
//               else {
//                 console.log("data is already available")
//               }
              
//             });
           
//             const data = await fetchCurrencydata(extractedDatesAndTimes2);
//             // setStockData(data);
//            console.log("data------------->" , data)
//            setModelsData(data);
//              // Check for unsupported country error
         
//           } 
          
//           else {
//             console.log(
//               "No data found in localStorage for key '4GraphDataobjects'"
//             );
           
//           }
//         }
  
//         else{
//           console.log("No data available in local storage");
//           // setLoading(false);
//           // setNoDataAvailable(true); 
//         }
//       }
//        catch (error) {
//         console.error("Error fetching stock data:", error);
       
       
//       }
//       finally {
        
//       }
//     };
  
//     const fetchCurrencydata = async (extractedDatesAndTimes2) => {
//       try {
//         // console.log("extractedDatesAndTimes2 bcakend" , extractedDatesAndTimes2)
//         const response = await fetch(`${process.env.REACT_APP_URL}/getLiveTimeseries_chartdata`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json'
//           },
//           body: JSON.stringify({ extractedDatesAndTimes2})
    
//         });
//         // console.log("extractedDatesAndTimes2" , extractedDatesAndTimes2)
//         const data = await response.json();
//         console.log("datahlo-------------->", data);
       
//         return data;
//       } catch (error) {
//         console.error("Error fetching stock data:", error);
//         throw error;
//       }
//     };
    
//     useEffect(() => {
//       fetchTimeseriesData();
//     }, []);
// //   const getlivegraph_timeseries_data = async()=>{
// //     try{
// //     //  const urlParams = new URLSearchParams(window.location.search);
// //     //  const eventName = decodeURIComponent(urlParams.get("eventName"));
 
// //     //  const eventcountry = decodeURIComponent(urlParams.get("eventcountry"));
 
// //        const eventandcountrydata = localStorage.getItem("eventnameandcountry:");
// //       const eventDataObj = JSON.parse(eventandcountrydata);

// //       const { eventName, eventcountry } =eventDataObj;
// //      const userid = localStorage.getItem("userregisterId")
// //      const response = await axios.get(`${process.env.REACT_APP_URL}/user/live_timeseries_data/${userid}/${eventName}/${eventcountry}`)
 
// //      console.log("response" , response.data)
// //      const finaldata = response.data.extractedDatesAndTimes2
// //       fetchCurrencydata(finaldata)


 
// //     }
// //     catch (error) {
// //      console.error("Error fetching data:", error);
   
// //    }
// //  }


// //  useEffect(()=>{
// //     getlivegraph_timeseries_data();
// //  },[])

// const renderGraphs = () => {
//   return modelsData.map((modelData, index) => {
//     if (modelData.data && modelData.data.length > 0) {
//       const dates = modelData.data.map((d) => d.date);
//       const apValues = modelData.data.map((d) => d.ap);
//       const bpValues = modelData.data.map((d) => d.bp);

//       const chartData = {
//         series: [
//           { name: "AP", data: apValues },
//           { name: "BP", data: bpValues },
//         ],
//         options: {
//           chart: { type: "line", zoom: { enabled: true } },
//           xaxis: { categories: dates },
//           title: { text: modelData.model, align: "center" },
//         },
//       };

//       return (
//         <div key={index} style={{ marginBottom: "30px" }}>
//           <react
//             options={chartData.options}
//             series={chartData.series}
//             type="line"
//             width="600"
//           />
//         </div>
//       );
//     }
//     return null; // Skip models with no data
//   });
// };

//   return (
// <>
//     <h1 className="font-bold text-[15px]">Live Charts</h1>
//     <div className="m-6">
//       {/* <Chart
//         options={chartData.options}
//         series={chartData.series}
//         type="line"
//         width="500"
//       /> */}
//        {/* {modelsData.length > 0 ? renderGraphs() : <p>Loading charts...</p>} */}
    
//     </div>
//     </>
//   );
// };

// export default LiveCharts;



import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactApexChart from "react-apexcharts";

const LiveCharts = () => {
  const [modelsData, setModelsData] = useState([]);

  // const fetchTimeseriesData = async () => {
  //   try {
  //     const eventandcountrydata = localStorage.getItem("eventnameandcountry:");
  //     const eventDataObj = JSON.parse(eventandcountrydata);
  //     const { eventName, eventcountry } = eventDataObj;

  //     let graphdataarray = localStorage.getItem("4GraphDataobjects");

  //     if (graphdataarray) {
  //       graphdataarray = JSON.parse(graphdataarray);

  //       if (graphdataarray.length > 0) {
  //         const current_Item = graphdataarray.find(
  //           (item) => item.event === eventName && item.country === eventcountry
  //         );

  //         if (current_Item) {
  //           let extractedDatesAndTimes2 = [];
  //           current_Item.four_graph_values.forEach((graphItem) => {
  //             const { date, time } = graphItem;
  //             if (
  //               !extractedDatesAndTimes2.some(
  //                 (item) => item.date === date && item.time === time
  //               )
  //             ) {
  //               extractedDatesAndTimes2.push({ date, time, country });
  //             }
  //           });

  //           const data = await fetchCurrencydata(extractedDatesAndTimes2);
  //           setModelsData(data);
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching stock data:", error);
  //   }
  // };



  const fetchTimeseriesData = async () => {
          try {
            // const urlParams = new URLSearchParams(window.location.search);
            // const eventName = decodeURIComponent(urlParams.get('eventName'));
            // const eventcountry = decodeURIComponent(urlParams.get('eventcountry'));
          const eventandcountrydata = localStorage.getItem("eventnameandcountry:");
          console.log("eventandcountrydata" , eventandcountrydata)
          const eventDataObj = JSON.parse(eventandcountrydata);
    
          const { eventName, eventcountry } = eventDataObj;
            let graphdataarray = localStorage.getItem("4GraphDataobjects");
      
            if (graphdataarray) {
              graphdataarray = JSON.parse(graphdataarray);
              // console.log("graphdataarray" , graphdataarray)
              if (graphdataarray.length > 0) {
      
                const current_Item = graphdataarray.find(
                  (item) => item.event === eventName && item.country === eventcountry
                );
                // const lastItem = graphdataarray[graphdataarray.length - 1];
                // console.log("current_Item" , current_Item)
               
      
                const event = current_Item.event;
                // seteventname(event); 
                const country = current_Item.country;
                // setcountryname(country);
                let extractedDatesAndTimes2 = [];
      
                current_Item.four_graph_values.forEach((graphItem) => {
                  const { date, time } = graphItem;
                  // <------if same date and time is not available then push the data ------------>
                  if (
                    !extractedDatesAndTimes2.some(
                      (item) => item.date === date && item.time === time
                    )
                  ) {
                    extractedDatesAndTimes2.push({ date, time, country });
                  }
                  else {
                    console.log("data is already available")
                  }
                  
                });
               
                const data = await fetchCurrencydata(extractedDatesAndTimes2);
                // setStockData(data);
               console.log("data------------->" , data)
               setModelsData(data);
                 // Check for unsupported country error
             
              } 
              
              else {
                console.log(
                  "No data found in localStorage for key '4GraphDataobjects'"
                );
               
              }
            }
      
            else{
              console.log("No data available in local storage");
              // setLoading(false);
              // setNoDataAvailable(true); 
            }
          }
           catch (error) {
            console.error("Error fetching stock data:", error);
           
           
          }
          finally {
            
          }
        };
      
  const fetchCurrencydata = async (extractedDatesAndTimes2) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/getLiveTimeseries_chartdata`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ extractedDatesAndTimes2 }),
        }
      );

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching stock data:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchTimeseriesData();
  }, []);

  const renderGraphs = () => {
    return modelsData.map((modelData, index) => {
      if (modelData.data && modelData.data.length > 0) {
        const dates = modelData.data.map((d) => d.date);
        const apValues = modelData.data.map((d) => d.ap);
        const bpValues = modelData.data.map((d) => d.bp);

        const chartOptions = {
          chart: { type: "line", zoom: { enabled: true } },
          xaxis: { categories: dates },
          title: { text: modelData.model, align: "center" },
          stroke: {
            width: 2, // Adjust this value for thinner or thicker lines
          },
        };

        const chartSeries = [
          { name: "AP", data: apValues },
          { name: "BP", data: bpValues },
        ];

        return (
          <div key={index} style={{ marginBottom: "30px" }}>
            <ReactApexChart
              options={chartOptions}
              series={chartSeries}
              type="line"
              width="600"
            />
          </div>
        );
      }
      return null; // Skip models with no data
    });
  };

  return (
    <>
      <h1 className="font-bold text-[15px]">Live Charts</h1>
      <div className="m-6">
        {modelsData.length > 0 ? renderGraphs() : <p>Loading charts...</p>}
      </div>
    </>
  );
};

export default LiveCharts;
