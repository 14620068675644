
// <-----------------this whole code is for charts , for now they are not in use ------------------>

export const fetchStockData = async (extractedDatesAndTimes) => {
  try {
    // console.log("extractedDatesAndTimes bcakend" , extractedDatesAndTimes)
    const response = await fetch(`${process.env.REACT_APP_URL}/graph`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ extractedDatesAndTimes})

    });
    // console.log("extractedDatesAndTimes" , extractedDatesAndTimes)
    const data = await response.json();
    console.log("datahlo-------------->", data);
   
    return data;
  } catch (error) {
    console.error("Error fetching stock data:", error);
    throw error;
  }
};



// <---hi new code---->