import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import { LineWave } from "react-loader-spinner";
// importUserTest from "../components/admin/adminTest"
import UserNavbar from "../../components/user/userNavbar";
import styles from "../../components/tradeall.module.css";
import { LineWave } from "react-loader-spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HiOutlineZoomOut } from "react-icons/hi";
import { HiOutlineZoomIn } from "react-icons/hi";
import reset_icon from "../../assets/circular.png";
import reduce_icon from "../../assets/minimize.png";
import { IoArrowBack } from "react-icons/io5";
import crossicon from "../../assets/cross.png";
const Userbuysell = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [modalloading, setModalLoading] = useState(false);
  // const [loadingSell, setLoadingSell] = useState(false);

  const [iframeHeight1, setIframeHeight1] = useState("38vh");
  const [iframeHeight2, setIframeHeight2] = useState("38vh");
  // const [iframeHeight3, setIframeHeight3] = useState("38vh");

  const [iframeHeight4, setIframeHeight4] = useState("38vh");
  const [iframeHeight5, setIframeHeight5] = useState("38vh");
  const [iframeHeight6, setIframeHeight6] = useState("38vh");

  const [latestevent, setLatestevent] = useState([]);
  const [lookupdata, setLookupdata] = useState([]);
  const [iframeSrc, setIframeSrc] = useState(
    "https://www.ig.com/content/dam/publicsites/dev/mt4/MT4_WEB.html"
  );
  const [showModal, setShowModal] = useState(false);
  const [newSrc, setNewSrc] = useState("");
  const [portfolioBalance, setPortfolioBalance] = useState("");
  const [profitTarget, setprofitTarget] = useState("");
  const [lossLimit, setlossLimit] = useState("");

  const [currenctprize2 , setCurrenctprize2] = useState("")
  const currencies = ["CHF", "AUD", "GBP", "USD", "CAD", "JPY", "NZD" , "EUR"];
  const currencySymbols = {
    CHF: "CHF",
    AUD: "A$",
    GBP: "£",
    USD: "$",
    CAD: "C$",
    JPY: "¥",
    NZD: "NZ$",
    EUR: "€"

  };
  const allPairs = [
    "GBP/USD",
    "GBP/JPY",
    "GBP/NZD",
    "EUR/GBP",
    "GBP/CHF",
    "GBP/AUD",
    "GBP/CAD",
    "USD/JPY",
    "NZD/USD",
    "EUR/USD",
    "USD/CHF",
    "AUD/USD",
    "USD/CAD",
    "NZD/JPY",
    "EUR/JPY",
    "CHF/JPY",
    "AUD/JPY",
    "CAD/JPY",
    "NZD/CAD",
    "EUR/CAD",
    "CAD/CHF",
    "AUD/CAD",
    "NZD/CHF",
    "EUR/CHF",
    "EUR/AUD",
    "AUD/NZD",
    "EUR/NZD",
  ];

  const all_lottypes = [ "Standard (100000)" , "Mini (10000)" , "Micro (1000)" ]
  const marginRatios = [
    "5:1",
    "10:1",
    "20:1",
    "25:1",
    "30:1",
    "40:1",
    "50:1",
    "75:1",
    "100:1",
    "125:1",
    "150:1",
    "200:1",
    "300:1",
    "400:1",
    "500:1",
    "1000:1",
  ];

  const allCurrencyPairs = [
    "GBPUSD",
    "GBPJPY",
    "GBPNZD",
    "EURGBP",
    "GBPCHF",
    "GBPAUD",
    "GBPCAD",
    "USDJPY",
    "NZDUSD",
    "EURUSD",
    "USDCHF",
    "AUDUSD",
    "USDCAD",
    "NZDJPY",
    "EURJPY",
    "CHFJPY",
    "AUDJPY",
    "CADJPY",
    "NZDCAD",
    "EURCAD",
    "CADCHF",
    "AUDCAD",
    "NZDCHF",
    "EURCHF",
    "EURAUD",
    "AUDNZD",
    "EURNZD",
  ];

  const [prepopulateMargindata , setPrepopulateMargindata] = useState("")
  const [accountCurrency, setAccountCurrency] = useState("");
  const [currencyPair, setCurrencyPair] = useState("");
  const [tradeSize, setTradeSize] = useState("");
  const [marginRatio, setMarginRatio] = useState("");
  const [maxLots, setMaxLots] = useState("");

  const [minimumLotSize, setminimumLotSize] = useState("");
  const [lotType, setLotType] = useState("");
  const [currencyUnits, setCurrencyUnits] = useState("");
  const [profitTargetPips, setprofitTargetPips] = useState("");
  const [lossLimitPips, setlossLimitPips] = useState("");

  const [currenctprize, setCurrenctprize] = useState("");

  const [currenctprize_forsamecurrency, setCurrenctprize_forsamecurrency] = useState("");
  const [finalmarginvalue, setFinalmarginvalue] = useState("");

  const [isVisible, setIsVisible] = useState(true);
  const [isVisible2, setIsVisible2] = useState(true);
  const [isVisible3, setIsVisible3] = useState(true);
  const [isVisible4, setIsVisible4] = useState(true);
  const [isVisible6, setIsVisible6] = useState(true);
  const [accountCurrency2, setAccountCurrency2] = useState("");
  const [tradeSize2, setTradeSize2] = useState("");

  const [finalPipvalue, setFinalPipvalue] = useState("");
  const [livetradeTime , setlivetradeTime] = useState("");
  const [livetradeTime_forsamecurrency , setlivetradeTime_forsamecurrency ] = useState("");
 const [errorForEstimateandPrevious , setErrorForEstimateandPrevious] = useState("")
 

  const PipValueCalculate = (pipDecimal) => {
    if (!accountCurrency || !currencyPair) return "";
  
    const derivedPair = getDerivedPair(); 
    // console.log("derivedPair" , derivedPair)
  
    let derivedBase = "", derivedQuote = "";
    if (derivedPair.includes("/")) {
      [derivedBase, derivedQuote] = derivedPair.split("/");
    } else {
      derivedBase = derivedPair.slice(0, 3);
      derivedQuote = derivedPair.slice(3);
    }
    const [base, quote] = currencyPair.split("/"); // Extract base and quote for the currency pair
    let pipValue = null;

    // Case 1: Account currency is in the denominator
    if (accountCurrency === derivedQuote) {
    
      if (accountCurrency != quote && accountCurrency != base  ) {
      
        pipValue =  (pipDecimal / currenctprize_forsamecurrency) * currencyUnits * currenctprize;
        // console.log("pipDecimal" , pipDecimal)
        // console.log("currenctprize" , currenctprize)
        // console.log("currencyUnits" , currencyUnits)
        // console.log("currenctprize_forsamecurrency" , currenctprize_forsamecurrency)
        // console.log("hi ashi i am in 1 " , pipValue)
      }
    }
  
    // Case 2: Account currency is in the numerator
    if (accountCurrency === derivedBase) {
      if (accountCurrency != quote && accountCurrency != base ) {
      
        pipValue =  ((pipDecimal / currenctprize_forsamecurrency) * currencyUnits) / ( currenctprize);
        // console.log("hi ashi i am in 2" , pipValue)
      } 
      
    }
    //Case 3 - Account currency is in numerator
    if (accountCurrency === derivedBase || accountCurrency === derivedQuote) {
      if (accountCurrency ===  base ) {
      
        pipValue =  (pipDecimal / (1/currenctprize)) * (currencyUnits);
        // console.log("hi ashi i am in 3 " , pipValue)
        // console.log("pipDecimal" , pipDecimal)
        // console.log("1/currenctprize" , 1/currenctprize)
        // console.log("currencyUnits" , currencyUnits)
        // console.log("currenctprize_forsamecurrency" , currenctprize_forsamecurrency)
        // console.log("hi ashi i am in 3 " , pipValue)
      } 
      
    }
     //Case 4 - Account currency is in denominators
     if (accountCurrency === derivedQuote || accountCurrency === derivedBase) {
      if (accountCurrency === quote  ) {
      

        pipValue =  (pipDecimal / (currenctprize)) * currencyUnits *  currenctprize;
        // console.log("hi ashi i am in 4 " , pipValue)
        // console.log("pipDecimal" , pipDecimal)
        // console.log("currenctprize" , currenctprize)
        // console.log("currencyUnits" , currencyUnits)
        // console.log("currenctprize_forsamecurrency" , currenctprize_forsamecurrency)
        // console.log("hi ashi i am in 4 " , pipValue)
      } 
      
    }
  
   
    if (pipValue !== null) {
      setFinalPipvalue(pipValue.toFixed(3));
    } else {
      setFinalPipvalue(null);
    }
  // console.log("final pip value " , pipValue)
    return pipValue;
    
  };
  


  const findMarginCalculator = ()=>{
    if (!tradeSize || !currenctprize || !marginRatio || !currencyPair) {
      // alert("");
      // toast.error("Please fill in all required fields!", {
      //   position: "top-right",
      // });
      return;
    }

    const leverage = parseFloat(marginRatio.split(":")[0]); // Extract leverage value
    const tradeSizeValue = parseFloat(tradeSize);
    const currentPriceValue = parseFloat(currenctprize);

    if (isNaN(leverage) || isNaN(tradeSizeValue) || isNaN(currentPriceValue)) {
      // alert("Please enter valid numeric values!");
      toast.error("Please enter valid numeric values!", {
        position: "top-right",
      });
      return;
    }

    
    const derivedPair = getDerivedPair();
    let derivedBase = "", derivedQuote = "";
    if (derivedPair.includes("/")) {
      [derivedBase, derivedQuote] = derivedPair.split("/");
    } else {
      derivedBase = derivedPair.slice(0, 3);
      derivedQuote = derivedPair.slice(3);
    }
    const [base, quote] = currencyPair.split("/"); 
    
   let marginRequired;
  //  <--num-->
  
    if (accountCurrency ===  base ){
      console.log("hlo i ma here in numer")
       marginRequired = ((currencyUnits / leverage ) * 1) ;  
       console.log("marginRequired1" , marginRequired)  /// trdesize with currncyunit
    }
    else if (accountCurrency === quote){
      console.log("hlo i ma here in denom")
      marginRequired = ((currencyUnits / leverage)  * currentPriceValue) ;  
    }
  
   else{
    console.log("hlo i both")
     marginRequired = (currencyUnits * currentPriceValue) / leverage; 
     console.log("marginRequired3" , marginRequired)  /// trdesize with currncyunit
   }
   console.log("marginRequired" , marginRequired)
   setFinalmarginvalue(marginRequired);

  }

useEffect(()=>{
  findMarginCalculator()
},[currenctprize_forsamecurrency , minimumLotSize , currenctprize , currencyPair ,currencyUnits , marginRatio , tradeSize])

  const handleCalculate = async () => {
  if (finalPipvalue && finalmarginvalue  ) {
      saveMarginCalculatordata();
     }
    // if (finalmarginvalue ) {
    //   saveMarginCalculatordata();
    //  }
   
  };



  // useEffect(()=>{
  //   handleCalculate();
  // },[currenctprize])

useEffect(()=>{
  let pipDecimal;
  if (currencyPair.includes("JPY")) {
    pipDecimal = 0.01;
  } else {
    pipDecimal = 0.0001;
  }
  
  PipValueCalculate(pipDecimal);

},[ currenctprize_forsamecurrency , minimumLotSize , currenctprize , currencyPair])






  const saveMarginCalculatordata = async () => {
    setModalLoading(false)
    try {
     
      const userid = localStorage.getItem("userregisterId");

      // console.log("hlo trial" , accountCurrency ,currencyPair , tradeSize ,marginRatio  , currenctprize)
      if (
        !accountCurrency ||
        !currencyPair ||
        !tradeSize ||
        !marginRatio ||
        !currenctprize ||
        !finalmarginvalue ||
        !finalPipvalue ||
        !portfolioBalance ||
        !profitTarget ||
        !lossLimit ||
        !lotType || 
        !currencyUnits  ||
        !profitTargetPips ||  
        !lossLimitPips  || 
        !maxLots || !minimumLotSize
      ) {
        toast.warning(`Please fill all inputs to save !`, {
          position: "top-right",
        });
        setModalLoading(false)
        return;
       
      } 
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/user/savedmarginCalculatordata/${userid}`,
          {
            accountCurrency,
            currencyPair,
            tradeSize,
            marginRatio,
            currenctprize,
            finalmarginvalue,
            finalPipvalue,
            portfolioBalance,
            profitTarget,
            lossLimit,
            lotType , 
            currencyUnits , 
            profitTargetPips , 
            lossLimitPips , 
            maxLots , minimumLotSize
          }
        );

        toast.success("Data saved successfully!", {
          position: "top-right",
        });
        setModalLoading(false)
    
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error(`Error in saving data !`, {
        position: "top-right",
      });
      setModalLoading(false)
    }
  };
  // useEffect(() => {
  //    if (finalPipvalue && finalmarginvalue && profitTargetPips &&  lossLimitPips && maxLots) {
  //    saveMarginCalculatordata();
  //   }
  // }, [finalPipvalue  , maxLots , profitTargetPips , lossLimitPips , finalmarginvalue]); // Trigger save when finalPipvalue is updated
  
  // const [liveprice, setLiveprice] = useState("");

  

  const livecurrenctprice = async (currency ) => {
    try {
      // console.log("hi ashi am here" , currencyPair)
      const live_currency_name = currency;
      // const live_currency_name_samevalue = currencyPair
      // console.log("live_currency_name", live_currency_name);
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/user/get_currenctpriceforCalculator`,
        { live_currency_name }
      );

      // console.log("response of currentprice ashi for diff" , response.data)
      let finalprice ;
      let p1;
      if(response.data.note){
         p1 = response.data.getliveprice;
       finalprice = (1/(p1?.ap)).toFixed(3);
      }
      else{

         p1 = response.data.getliveprice;
        finalprice = p1?.ap; // Check if `ap` exists
      }
      
      // console.log("response", response.data.currencies);
     
      // console.log("Final price----:", finalprice);
      const finalpriceTime = p1?.updatedAt;
      let formattedDate = "Time not available";
      if (finalpriceTime) {
        const dateObject = new Date(finalpriceTime); // Ensure it's a Date object
        if (!isNaN(dateObject)) { // Check if it's a valid date
          formattedDate =
            dateObject.toISOString().slice(0, 10).split("-").reverse().join("-") +
            " " + " , " + 
            dateObject.toISOString().slice(11, 19);
        }
      }
     


      // console.log("formattedDate" , formattedDate)
      if (response.status === 200) {
        // setLiveprice(finalprice || "Price not available");
        setCurrenctprize(finalprice || "Price not available");
        setlivetradeTime(formattedDate || "Time not available");
      
      } else {
        // setLiveprice(""); // Handle other non-200 cases gracefully
        setCurrenctprize("");
        setlivetradeTime("")
       
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 404) {
        // setLiveprice("");
        setCurrenctprize("");
        setlivetradeTime("")
       
        toast.error("Currency not found in backend", { position: "top-right" });
      } else {
        toast.error("Failed to fetch current price", { position: "top-right" });
      }
    }
  };



  const livecurrenctprice_ofsamecurrency = async (same_currency ) => {
    try {
      // console.log("hi ashi am here" , currencyPair)
      const live_samecurrency_name = same_currency;
      // const live_currency_name_samevalue = currencyPair
      // console.log("live_currency_name", live_samecurrency_name);
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/user/get_currenctpriceforCalculatorforsamecurrency`,
        { live_samecurrency_name }
      );

      // console.log("response of currentprice ashi" , response.data)
      let finalprice ;
      let p1;
      if(response.data.note){
         p1 = response.data.getliveprice;
       finalprice = (1/(p1?.ap)).toFixed(3);
      }
      else{

         p1 = response.data.getliveprice;
        finalprice = p1?.ap; // Check if `ap` exists
      }
      
      // console.log("response", response.data.currencies);
     
      // console.log("Final price----:", finalprice);
      const finalpriceTime = p1?.updatedAt;
      let formattedDate = "Time not available";
      if (finalpriceTime) {
        const dateObject = new Date(finalpriceTime); // Ensure it's a Date object
        if (!isNaN(dateObject)) { // Check if it's a valid date
          formattedDate =
            dateObject.toISOString().slice(0, 10).split("-").reverse().join("-") +
            " " + " , " + 
            dateObject.toISOString().slice(11, 19);
        }
      }
     


      // console.log("formattedDate" , formattedDate)
      if (response.status === 200) {
        // setLiveprice_forsamecurrency(finalprice || "Price not available");
        setCurrenctprize_forsamecurrency(finalprice || "Price not available");
        setlivetradeTime_forsamecurrency(formattedDate || "Time not available");
      
      } else {
        // setLiveprice_forsamecurrency(""); // Handle other non-200 cases gracefully
        setCurrenctprize_forsamecurrency("");
        setlivetradeTime_forsamecurrency("")
       
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 404) {
        // setLiveprice_forsamecurrency("");
        setCurrenctprize_forsamecurrency("");
        setlivetradeTime_forsamecurrency("")
       
        toast.error("Currency not found in backend", { position: "top-right" });
      } else {
        toast.error("Failed to fetch current price", { position: "top-right" });
      }
    }
  };


  // const getDerivedPair = () => {
  //   if (!accountCurrency || !currencyPair) return "";

  //   const [base, quote] = currencyPair.split("/");

  //   if (accountCurrency === base) {
  //     const derivedPair = `${quote}/${accountCurrency}`;
  //     livecurrenctprice(derivedPair.replace("/", "") , currencyPair.replace("/", "")); // Fetch live price if necessary
  //     return derivedPair;
  //   }

  //   if (accountCurrency === quote) {
  //     const derivedPair = `${base}/${accountCurrency}`;
  //     livecurrenctprice(derivedPair.replace("/", "") , currencyPair.replace("/", ""));
  //     return derivedPair;
  //   }

  //   // Case 3: Account currency is different from both base and quote
  //   const derivedPair = `${base}${accountCurrency}`;
  //   livecurrenctprice(derivedPair , currencyPair.replace("/", ""));
  //   return derivedPair;
  // };

  const getDerivedPair = () => {
    if (!accountCurrency || !currencyPair) return "";

    const [base, quote] = currencyPair.split("/");

    if (accountCurrency === base) {
      const derivedPair = `${quote}/${accountCurrency}`;
      livecurrenctprice(derivedPair.replace("/", "") ); // Fetch live price if necessary
      livecurrenctprice_ofsamecurrency(currencyPair.replace("/", ""))
      return derivedPair;
    }

    if (accountCurrency === quote) {
      const derivedPair = `${base}/${accountCurrency}`;
      livecurrenctprice(derivedPair.replace("/", "") );
      livecurrenctprice_ofsamecurrency(currencyPair.replace("/", ""))
      return derivedPair;
    }

    // Case 3: Account currency is different from both base and quote
    const derivedPair = `${base}${accountCurrency}`;
    livecurrenctprice(derivedPair);
    livecurrenctprice_ofsamecurrency(currencyPair.replace("/", ""))
    return derivedPair;
  };

  const modalRef = useRef(null);
  
  const handleOpenModal = () => {
    setShowModal(true);
    // fetchsetShowModalPrices();
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setNewSrc("");
  };


  const findCurrenyUnits = ()=>{
    const lotTypeValues = {
      "Standard (100000)": 100000,
      "Mini (10000)": 10000,
      "Micro (1000)": 1000,
    };

    if (tradeSize && lotType && lotTypeValues[lotType]) {
      setCurrencyUnits(tradeSize * lotTypeValues[lotType]);
    } else {
      setCurrencyUnits("");
    }
  }

  const ProfitTargetUnits = ()=>{
    if (finalPipvalue && portfolioBalance && profitTarget && currencyUnits) {
      const data = ((portfolioBalance * profitTarget) / (finalPipvalue * currencyUnits)).toFixed(4)  
      setprofitTargetPips(data);
      // console.log("data----" , data)
    } else {
      setprofitTargetPips("");
    }
  }

  const LossTargetUnits = ()=>{
    if (finalPipvalue && portfolioBalance && lossLimit && currencyUnits) {
      const losstargetvalue =((portfolioBalance * lossLimit) / (finalPipvalue * currencyUnits)).toFixed(4)
      setlossLimitPips(losstargetvalue);
    
      // console.log("losstargetvalue" , losstargetvalue )
    } else {
      setlossLimitPips("");
    }
  }

  const findmaxLots = ()=>{
    if (finalmarginvalue && portfolioBalance ) {
      setMaxLots(((portfolioBalance) / finalmarginvalue).toFixed(4) );
    } else {
      setMaxLots("");
    }
   }
  useEffect(()=>{
    findmaxLots();
  },[portfolioBalance , finalmarginvalue , finalPipvalue , profitTarget , lossLimit , lotType])


  useEffect(() => {
    findCurrenyUnits();
  }, [lotType, tradeSize]);

  useEffect(()=>{
    ProfitTargetUnits();
    LossTargetUnits();
  },[portfolioBalance , finalPipvalue  , currencyUnits , profitTarget , lossLimit])

  const handleSubmit = () => {
    setIframeSrc(newSrc);
    handleCloseModal();
  };
  //   const handleClick = async (value) => {
  //     try {
  //       if (value === "buy") {
  //         setLoading(true);
  //       } else {
  //         setLoadingSell(true);
  //       }
  //       const userid = localStorage.getItem("adminregisterId");
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_URL}/postbuysell`,
  //         {
  //           userid: userid,
  //           value: value,
  //         }
  //       );

  //       console.log(response);
  //       if (value === "buy") {
  //         setLoading(false);
  //       } else {
  //         setLoadingSell(false);
  //       }
  //       toast.success(`${value} command send successfully!`, {
  //         position: "top-right",
  //       });
  //     } catch (error) {
  //       console.error("Error posting buy/sell data:", error);
  //       toast.error(`Error in sending ${value} command!`, {
  //         position: "top-right",
  //       });
  //       if (value === "buy") {
  //         setLoading(false);
  //       } else {
  //         setLoadingSell(false);
  //       }
  //     }
  //   };

  const fetchlatest_event = async () => {
    try {
      const userid = localStorage.getItem("userregisterId");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/user/getlivetrade_upcomingdata/${userid}`
      );
      if (response.data.status === 200) {
        // setErrorMessage('');
        // console.log("response.data" ,response.data)
        const future_data = response.data?.futureEvents;
        const futureeventdata = future_data?.map((item) => item).flat();
        console.log("futureeventdata" , futureeventdata)
        if (futureeventdata.length > 0) {
          setLatestevent(futureeventdata);
          // setLatestevent([]);
          setLoading(false);
        } else {
          console.log("No future events found");
          setLoading(false);
          setLatestevent([]);
        }
      } else {
        setLatestevent([]);
        setLoading(false);
        // setErrorMessage('No future trades available');
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching messages:", error);
    }
  };
 const [lookupdata2 , setLookupdata2] = useState([])
  const fetchlookup_result = async () => {
    try {
      const userid = localStorage.getItem("userregisterId");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/user/getlivetrade_lookuptabledata/${userid}`
      );


      console.log("response hi bro ai m here" , response.data)
      if (response.data.status === 200) {
        
        // console.log("response.data" ,response.data)
        const future_data = response.data?.futureEvents;
        const invalid_data = response.data?.invalidEvents || [];
      
        const futureeventdata = Array.isArray(future_data) ? future_data.flat(): [];
        const invalidEventData = Array.isArray(invalid_data) ? invalid_data.flat() : [];
        const finalComments = futureeventdata.map((item) => item.finalComment);
        // const finalComments_forinvalidData = invalidEventData.map((item) => item.finalComment);
        // console.log("Final Comments (d1):", finalComments);

         // Log and process invalid trades
         const finalComments_forinvalidData=  invalidEventData.map((item) => {
         
            console.log("trade.reason" , item.reason); 
            return  item.reason ;
          });
          console.log("finalComments_forinvalidData" , finalComments_forinvalidData)
        if (futureeventdata.length > 0) {
          setLookupdata(finalComments);
          setLookupdata2(finalComments_forinvalidData)
          setLoading(false);
        } else {
          console.log("No future events found");
          setLoading(false);
          setLatestevent([]);
        }
      } 
      
      else {
        setLatestevent([]);
        setLoading(false);
        setLookupdata([]);
      }
    } catch (error) {
      setLoading(false);
      setLookupdata([]);
      console.error("Error fetching messages:", error);
    }

  };


  useEffect(() => {
    fetchlatest_event();
    fetchlookup_result();
  }, []);

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      handleCloseModal();
    }
   
  };
  const Closemodal = ()=>{
    handleCloseModal();
  }

  const getmargindataandpopulate = async()=>{
  try{
   const userid = localStorage.getItem("userregisterId")

   const get_response = await axios.get(`${process.env.REACT_APP_URL}/user/getmaginCalculatorDataandprepopulate/${userid}`);

   const response1 = get_response.data;
   const getMarginData = response1.getuser[0]
  setPrepopulateMargindata(getMarginData )
   
  //  console.log("getMarginData" , getMarginData)
  //  toast.success("Data fetched successfully!", {
  //   position: "top-right",
  // });


  }
  catch(error){
    console.error("Error fetching data:", error);
    toast.error(`Error in fetching data !`, {
      position: "top-right",
    });
   
  }
  }

  useEffect(()=>{
    getmargindataandpopulate();
  }, [])



  const matchPopulatedata = () =>{
    if (prepopulateMargindata) {
      setAccountCurrency(prepopulateMargindata.accountCurrency);
      setMarginRatio(prepopulateMargindata.marginRatio);
      setTradeSize(prepopulateMargindata.tradeSize);
      setminimumLotSize(prepopulateMargindata.minimumLotSize);
      setCurrencyPair(prepopulateMargindata.currencyPair);
      setLotType(prepopulateMargindata.lotType);
      setCurrenctprize(prepopulateMargindata.currenctprize);
      setCurrencyUnits(prepopulateMargindata.currencyUnits);
      setPortfolioBalance(prepopulateMargindata.portfolioBalance);
      setprofitTarget(prepopulateMargindata.profitTarget);
      setprofitTargetPips(prepopulateMargindata.profitTargetPips);
      setMaxLots(prepopulateMargindata.maxLots);
      setFinalmarginvalue(prepopulateMargindata.finalmarginvalue);
      setFinalPipvalue(prepopulateMargindata.finalPipvalue);
      setlossLimit(prepopulateMargindata.lossLimit);
      setlossLimitPips(prepopulateMargindata.lossLimitPips);
    }
  }
  useEffect(()=>{
  matchPopulatedata();
  }, [prepopulateMargindata])

  const [scale, setScale] = useState(1); // Initial zoom level
  const [scale2, setScale2] = useState(1); // Initial zoom level
  const [scale3, setScale3] = useState(1);
  const [scale4, setScale4] = useState(1);
  const [scale6, setScale6] = useState(1);

  const zoomIn = () => setScale((prevScale) => Math.min(prevScale + 0.1, 3)); // Max zoom level
  const zoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.1, 0.5)); // Min zoom level// useEffect(() => {

  const zoomIn2 = () => setScale2((prevScale) => Math.min(prevScale + 0.1, 3)); // Max zoom level
  const zoomOut2 = () =>
    setScale2((prevScale) => Math.max(prevScale - 0.1, 0.5)); // Min zoom level// useEffect(() => {

  const zoomIn3 = () => setScale3((prevScale) => Math.min(prevScale + 0.1, 3)); // Max zoom level
  const zoomOut3 = () =>
    setScale3((prevScale) => Math.max(prevScale - 0.1, 0.5)); // Min zoom level// useEffect(() => {

  const zoomIn4 = () => setScale4((prevScale) => Math.min(prevScale + 0.1, 3)); // Max zoom level
  const zoomOut4 = () =>
    setScale4((prevScale) => Math.max(prevScale - 0.1, 0.5)); // Min zoom level// useEffect(() => {



      const zoomIn6 = () => setScale6((prevScale) => Math.min(prevScale + 0.1, 3)); // Max zoom level
  const zoomOut6 = () =>
    setScale6((prevScale) => Math.max(prevScale - 0.1, 0.5)); // Min zoom level// useEffect(() => {

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const toggleVisibility2 = () => {
    setIsVisible2(!isVisible2);
  };
  const toggleVisibility3 = () => {
    setIsVisible3(!isVisible3);
  };
  const toggleVisibility4 = () => {
    setIsVisible4(!isVisible4);
  };
  const toggleVisibility6 = () => {
    setIsVisible6(!isVisible6);
  };


  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden"; // Disable scrolling on the body
    } else {
      document.body.style.overflow = "auto"; // Re-enable scrolling
    }
    return () => {
      document.body.style.overflow = "auto"; // Clean up
    };
  }, [showModal]);
  
  const [prices, setPrices] = useState({});

  // const fetchPrices = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_URL}/user/get_allcurrenctpriceforPip`,
  //       { pairs: allCurrencyPairs } // Pass all pairs to your API if supported
  //     );
  //     console.log("response.data" , response.data)

  //     if (response.data.success) {
  //       // Process the API response directly
  //       const prices = response.data.prices;

  //       // Map the prices to the results you need and directly store them
  //       const pipResults = Object.entries(prices).map(([pair, data]) => {
  //         const price = data?.ap || "N/A"; // Use `ap` as the price
  //         return {
  //           pair,
  //           price,
  //         };
  //       });

  //       setPipResults(pipResults); // Directly store the results in the state
  //     } else {
  //       toast.error(response.data.message || "No prices found");
  //       setPipResults([]); // Clear results if no prices found
  //     }
  //   } catch (error) {
  //     console.error("Error fetching prices:", error);
  //     return {};
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // useEffect(() => {

  //   fetchPrices();
  // }, []);

  return (
    <>
      {/* <div className="flex justify-center items-center mt-2">
        <button
          className={`px-4 py-1 bg-[#2932a5] text-white rounded hover:bg-blue-600 ${
            loading ? "opacity-50 cursor-not-allowed disabled" : ""
          }`}
          onClick={() => handleClick("buy")}
        >
          Buy
        </button>
        <button
          className={`px-4 py-1 bg-red-500 text-white rounded hover:bg-red-600 m-2 ${
            loadingSell ? "opacity-50 cursor-not-allowed disabled" : ""
          }`}
          onClick={() => handleClick("sell")}
        >
          Sell
        </button>
      </div> */}

      {loading && (
        <div className="fixed top-[8%] text-center mb-2 flex items-center justify-center   w-full">
          <LineWave
            height="60"
            width="60"
            color="#111827"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            className=" w-10 h-10 flex items-center justify-center  mt-10 "
          />
        </div>
      )}
      <div>
        <UserNavbar />
      </div>

      <h1 className="flex items-center font-bold text-[18px] mt-[23%] sm:mt-[20%] md:mt-[12%] lg:mt-[10%] xl:mt-[7%] mx-[3%]">
        <IoArrowBack className="mr-2 cursor-pointer " onClick={() => navigate('/user/economicdata')} title="Back Button"/>
        Future Trade Event:
      </h1>

      {latestevent.length === 0 ? (
        <div className={` m-2 mt-0 text-center font-semibold text-xl `}>
          Sorry!! No Future trades available .
        </div>
      ) : (
        <div className="overflow-auto mx-[2%]">
          <div className="overflow-x-auto overflow-y-auto  ">
            <table
              className={` ${styles.tableuser} min-w-full  rounded overflow-x-scroll m-auto text-[16px] font-semibold`}
            >
              <thead className="bg-[#d0dcf4]   text-[#170E32]  sticky top-0 z-10 text-[16px] font-semibold border-b-[12px] border-solid border-[#F6F6F6] rounded-[8px]">
                <tr>
                  <th className=" sticky top-0 py-2 px-4">S. No.</th>
                  <th className=" sticky top-0 py-2 px-4">Date</th>
                  <th className=" sticky top-0 py-2 px-4">Time(GMT)</th>
                  <th className="sticky top-0 py-2 px-4">Country</th>
                  <th className="sticky top-0 py-2 px-4">Currency</th>
                  <th className="sticky top-0 py-2 px-4">Event</th>
                  <th className="sticky top-0 py-2 px-4">Previous</th>
                  <th className="sticky top-0 py-2 px-4">Estimate</th>
                  <th className="sticky top-0 py-2 px-4">Actual</th>
                  <th className="sticky top-0 py-2 px-4">Trade</th>
                  <th className="sticky top-0 py-2 px-4">Trade Grade</th>
                  <th className="sticky top-0 py-2 px-4">
                    Investing.com Var name
                  </th>
                  <th className="sticky top-0 py-2 px-4">
                    Trading view Var name
                  </th>
                  <th className="sticky top-0 py-2 px-4">
                    Trading Economics Var name
                  </th>
                  <th className="sticky top-0 py-2 px-4">
                    Non - Zero (Currency)
                  </th>
                  <th className="sticky top-0 py-2 px-4">
                    Non - Zero (Correlation)
                  </th>
                  <th className="sticky top-0 py-2 px-4">Flat (Currency)</th>
                  <th className="sticky top-0 py-2 px-4">Flat (Correlation)</th>
                  <th className="sticky top-0 py-2 px-4">Comments</th>
                  {/* <th className="sticky top-0 py-2 px-4">
         Prediction
       </th>
       <th className="sticky top-0 py-2 px-4">
         Trade log(profit/loss)
       </th>
       <th className="sticky top-0 py-2 px-4">
         Trade Amount
       </th>
       <th className="sticky top-0 py-2 px-4">
         Trade in time(seconds)
       </th>
       <th className="sticky top-0 py-2 px-4">
         Trade comment
       </th> */}
                  {/* <th className="sticky top-0 py-2 px-4">
         Select Trade
       </th> */}
                </tr>
              </thead>
              <tbody className="bg-[#F6F6F6]  z-0">
                {latestevent
                  .sort((a, b) => new Date(b.date) - new Date(a.date))
                  .map((item, index) => (
                    <>
                      <tr
                        key={index}
                        className="text-center border-y-[12px] border-solid border-[#F6F6F6] bg-white hover:bg-[#aecaf133]"
                      >
                        {/* { console.log("modalData" , modalData)} */}
                        <td className="py-2 px-4 min-w-[80px]">{index + 1}.</td>
                        <td className="py-2 px-4">{item.date}</td>
                        <td className="py-2 px-4">{item.time}</td>
                        <td className="py-2 px-4">{item.country}</td>
                        <td className="py-2 px-4">{item.currency}</td>
                        <td className="py-2 px-4 ml-[-5px] text-blue-500 cursor-pointer min-w-[200px] outline-none">
                          {item.event}
                        </td>
                        <td className="py-2 px-4 ml-[-5px] text-blue-500 cursor-pointer ">
                          {item.previous}
                        </td>
                        <td className="py-2 px-4 ml-[-5px] text-blue-500 cursor-pointer">
                          {item.estimate}
                        </td>
                        <td className="py-2 px-4 ml-[-5px] text-blue-500 cursor-pointer ">
                          {item.actual}
                        </td>

                        <td className="py-2 px-4">{item.trade}</td>
                        <td className="py-2 px-4">{item.trade_grade}</td>
                        <td className="py-2 px-4 min-w-[200px] outline-none">
                          {item.investing_name}
                        </td>
                        <td className="py-2 px-4 min-w-[200px] outline-none">
                          {item.trading_view}
                        </td>
                        <td className="py-2 px-4 min-w-[200px] outline-none">
                          {item.trading_economics}
                        </td>
                        <td className="py-2 px-4">{item.movement_currency}</td>
                        <td className="py-2 px-4">
                          {item.movement_correlation}
                        </td>
                        <td className="py-2 px-4">{item.flat_currency}</td>
                        <td className="py-2 px-4">{item.flat_correlation}</td>
                        <td className="py-2 px-4 min-w-[200px] outline-none">
                          {item.comments}
                        </td>
                        {/* <td className="py-2 px-4">
                   {item.prediction}
                 </td>
                 <td className="py-2 px-4">
                   {item.trade_log}
                 </td>
                 <td className="py-2 px-4">
                   {item.trade_amount}
                 </td>
                 <td className="py-2 px-4">
                   {item.trade_time}
                 </td>
                 <td className="py-2 px-4 min-w-[200px] outline-none">
                   {item.trade_comments}
                 </td> */}
                      </tr>
                    </>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <div className="flex justify-between items-start w-full flex-col sm:flex-col md:flex-row ml-[5%] sm:ml-[5%] md:ml-0">
        {/* {
  lookupdata.length > 0 ?  
  <div className="bg-[#e4ebf9] w-[50vw] py-4 px-4 text-[#170E32] font-semibold mt-2 ml-[3%]">
  {lookupdata.map((item, index) => (
    <div
      key={index}
      className={`mb-2 ${
        item.toLowerCase() === 'buy' ? 'text-green-500' : item.toLowerCase() === 'sell' ? 'text-red-500' : ''
      }`}
    >
      {item}
    </div>
  ))}
</div>

: <><div></div> </>
} */}
        <div>
        {lookupdata.length > 0 ? (
          <div className="bg-[#e4ebf9] sm:w-[86vw] w-[86vw] md:w-[50vw] py-4 px-4 text-[#170E32] font-bold mt-2 ml-[3%]">
            {/* {lookupdata.map((item, index) => (
              <div key={index} className="mb-2 text-[16px] sm:text-[16px] md:text-[20px]">
                
                {item.split(/(Sell|Buy)/).map((part, idx) => {
                  if (part === "Sell") {
                    return (
                      <span key={idx} className="text-red-500 text-[16px] sm:text-[16px] md:text-[20px]">
                        Currency pair expected to decrease
                      </span>
                    );
                  } else if (part === "Buy") {
                    return (
                      <span key={idx} className="text-green-500 text-[16px] sm:text-[16px] md:text-[20px]">
                        Currency pair expected to increase
                      </span>
                    );
                  }
                  return part;
                })}
              </div>
            ))} */}
            {lookupdata.map((item, index) => (
            <div key={index} className="mb-2 text-[16px] sm:text-[16px] md:text-[16px] xl:text-[20px]">
              {item.split(';').map((segment, segIndex) => (
                <div key={segIndex} className="mb-1">
                  {segment.split(/(Sell|Buy)/).map((part, idx) => {
                    if (part === "Sell") {
                      return (
                        <span key={idx} className="text-red-500 text-[16px] sm:text-[16px] md:text-[16px] xl:text-[20px]">
                          Currency pair expected to decrease
                        </span>
                      );
                    } else if (part === "Buy") {
                      return (
                        <span key={idx} className="text-green-500 text-[16px] sm:text-[16px] md:text-[16px] xl:text-[20px]">
                          Currency pair expected to increase
                        </span>
                      );
                    }
                    return part;
                  })}
                </div>
              ))}
            </div>
          ))}

          </div>
        ) : (
          <div>
          </div>
        )}
        
        {lookupdata2.length > 0 ? (
           <div className="bg-[#e4ebf9] sm:w-[86vw] w-[86vw] md:w-[50vw] py-4 px-4 text-[#170E32] font-bold mt-2 ml-[3%]">
        { lookupdata2.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>
        ) : (
          // <p>No invalid events found.</p>
          <div>
          </div>
        )}
        </div>

        <button
          onClick={handleOpenModal}
          className=" mt-4 px-4 py-1 bg-[#2932a5] text-white rounded-md mr-[3%]"
        >
          Prepare For Trade
        </button>
      </div>

      <div className="flex justify-around items-center w-full flex-col sm:flex-col md:flex-col lg:flex-col xl:flex-row mt-[4%]">
        <div>
          <div className="flex justify-around items-end w-[90vw] sm:w-[90vw] md:w-full">
            <div className="py-1 px-4 border border-gray-300 rounded">
              <div className="flex items-center space-x-2 cursor-pointer">
                <img
                  src={reduce_icon}
                  alt="reduce_icon"
                  className="h-[20px] w-[20px] mr-2 mt-1 font-bold "
                  onClick={() => setIframeHeight1("13vh")}
                  title="reduce height"
                />
                <img
                  src={reset_icon}
                  alt="reset_icon"
                  className="h-[20px] w-[20px] mr-2 mt-1 font-bold "
                  onClick={() => setIframeHeight1("38vh")}
                  title="reset height"
                />
              </div>
            </div>
            <div>
              {" "}
              <h2 className="text-black font-bold text-[14px] sm:text-[14px] md:text-[16px]">Trading Economics</h2>
            </div>
            <div className="flex justify-center mt-3 space-x-4">
              {/* <button
          onClick={zoomIn}
          className="px-4 py-1 bg-[#2932a5] text-white rounded-md hover:bg-blue-600"
        >
          Zoom In
        </button> */}

              <div className="py-1 px-4 border border-gray-300 rounded">
                <div className="flex items-center space-x-2">
                  <HiOutlineZoomIn
                    onClick={zoomIn}
                    className="cursor-pointer w-6 h-6"
                    title="zoomin"
                  />
                  <HiOutlineZoomOut
                    onClick={zoomOut}
                    className="cursor-pointer w-6 h-6"
                    title="zoomout"
                  />
                </div>
              </div>
              <button
                onClick={toggleVisibility}
                className=" px-4 py-1 bg-[#0059bf] text-white rounded hover:bg-[#023065] transition"
              >
                {isVisible ? "Hide" : "Unhide"}
              </button>
            </div>
          </div>
          {isVisible && (
            <div
              className="flex justify-center items-center mt-1 w-[95vw] sm:w-[95vw] md:w-[95vw] lg:w-[95vw] xl:w-[45vw]"
              style={{
                height: iframeHeight1, // Fixed container height
                overflow: "hidden", // Ensure no content spills outside
                position: "relative",
              }}
            >
              <iframe
                title="Trading economics"
                src="https://tradingeconomics.com/calendar"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
                className=" block    border-[1px]  border-slate-300  sm:border-[0px]  w-[95vw] sm:w-[100vw] md:w-[100vw] lg:w-[100vw] xl:w-[45vw] rounded-2xl"
                // style={{ height: iframeHeight1 }}
                style={{
                  width: "100%", // Fixed iframe width
                  height: "100%", // Fixed iframe height
                  transform: `scale(${scale}) translate(${
                    -((scale - 1) / scale) * 50
                  }%, ${-((scale - 1) / scale) * 50}%)`,
                  transformOrigin: "top left", // Anchor zoom at top left
                }}
              ></iframe>
            </div>
          )}
        </div>

        <div className="mx-[10px] sm:mx-[10px] md:mx-0">
          <div className="flex justify-around items-end w-[90vw] sm:w-[90vw] md:w-full ">
            <div className="py-1 px-4 border border-gray-300 rounded">
              <div className="flex items-center space-x-2 cursor-pointer">
                <img
                  src={reduce_icon}
                  alt="reduce_icon"
                  className="h-[20px] w-[20px] mr-2 mt-1 font-bold "
                  onClick={() => setIframeHeight2("13vh")}
                  title="reduce height"
                />
                <img
                  src={reset_icon}
                  alt="reset_icon"
                  className="h-[20px] w-[20px] mr-2 mt-1 font-bold "
                  onClick={() => setIframeHeight2("38vh")}
                  title="reset height"
                />
              </div>
            </div>

            <div className="mt-5 sm:mt-5 md:mt-0">
              <h2 className="text-black font-bold text-[14px] sm:text-[14px] md:text-[16px]"> Widget Dukascopy.com </h2>
            </div>
            <div className="flex justify-center mt-3 space-x-4">
              <div className="py-1 px-4 border border-gray-300 rounded">
                <div className="flex items-center space-x-2">
                  <HiOutlineZoomIn
                    onClick={zoomIn2}
                    className="cursor-pointer w-6 h-6"
                    title="zoomin"
                  />

                  <HiOutlineZoomOut
                    onClick={zoomOut2}
                    className="cursor-pointer w-6 h-6"
                    title="zoomout"
                  />
                </div>
              </div>
              <button
                onClick={toggleVisibility2}
                className=" px-4 py-1 bg-[#0059bf] text-white rounded hover:bg-[#023065] transition"
              >
                {isVisible2 ? "Hide" : "Unhide"}
              </button>
            </div>
          </div>

          {isVisible2 && (
            <div
              className="flex justify-center items-center mt-1 w-[95vw] sm:w-[95vw] md:w-[95vw] lg:w-[95vw] xl:w-[45vw]"
              style={{
                height: iframeHeight2, // Fixed container height
                overflow: "hidden", // Ensure no content spills outside
                position: "relative",
              }}
            >
              <iframe
                // title="Uk investing"
                // src="https://uk.investing.com/economic-calendar/"
                title="Dukascopy"
                
                src="https://freeserv.dukascopy.com/2.0/?path=economic_calendar_new/index&lang=en&showHeader=false&tableBorderColor=%23D92626&defaultTimezone=0&defaultCountries=c%3AAU%2CCA%2CCH%2CCN%2CEU%2CGB%2CJP%2CNZ%2CUS%2CDE%2CFR%2CIT%2CES&impacts=1%2C2&dateTab=2&dateFrom=1459036800000&dateTo=1459555200000&showColCountry=true&showColCurrency=true&showColImpact=true&showColPrevious=true&showColForecast=true&width=100%25&height=500&adv=popup"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
                className=" block   border-[1px]  border-slate-300  sm:border-[0px]  w-[95vw] sm:w-[100vw] md:w-[100vw] lg:w-[100vw] xl:w-[45vw] rounded-2xl"
                // style={{border:2px solid #4A4A4A}}
                style={{
                  // width: "100%", // Fixed iframe width
                  height: "100%", // Fixed iframe height
                  transform: `scale(${scale2}) translate(${
                    -((scale2 - 1) / scale2) * 50
                  }%, ${-((scale2 - 1) / scale2) * 50}%)`,
                  transformOrigin: "top left", // Anchor zoom at top left
                }}
              ></iframe>
              {/* <div>Hello World</div> */}
              {/* <AdminTest/> */}
            </div>
          )}
        </div>
      </div>

      <hr
        className="my-6"
        style={{ borderColor: "#2932a5", borderWidth: "1px" }}
      />
      {/* <-----------------widgets dukascopy--------> */}
      {/* <div className="flex justify-around items-center w-full flex-col sm:flex-col md:flex-col lg:flex-col xl:flex-row mt-6">
        <div>
          <div className="flex justify-around items-end">
            <div className="py-1 px-4 border border-gray-300 rounded">
              <div className="flex items-center space-x-2 cursor-pointer">
                <img
                  src={reduce_icon}
                  alt="reduce_icon"
                  className="h-[20px] w-[20px] mr-2 mt-1 font-bold "
                  onClick={() => setIframeHeight6("16vh")}
                  title="reduce height"
                />
                <img
                  src={reset_icon}
                  alt="reset_icon"
                  className="h-[20px] w-[20px] mr-2 mt-1 font-bold "
                  onClick={() => setIframeHeight6("38vh")}
                  title="reset height"
                />
              </div>
            </div>
            <div>
              <h2 className="text-black font-bold text-[14px] sm:text-[14px] md:text-[16px]"> Widget Dukascopy.com </h2>
            </div>
            <div className="flex justify-center mt-3 space-x-4">
              <div className="py-1 px-4 border border-gray-300 rounded">
                <div className="flex items-center space-x-2">
                  <HiOutlineZoomIn
                    onClick={zoomIn6}
                    className="cursor-pointer w-6 h-6"
                    title="zoomin"
                  />

                  <HiOutlineZoomOut
                    onClick={zoomOut6}
                    className="cursor-pointer w-6 h-6"
                    title="zoomout"
                  />
                </div>
              </div>
              <button
                onClick={toggleVisibility6}
                className=" px-4 py-1 bg-[#0059bf] text-white rounded hover:bg-[#023065] transition"
              >
                {isVisible3 ? "Hide" : "Unhide"}
              </button>
            </div>
          </div>

          {isVisible6 && (
            <div
              className="flex justify-center items-center mt-1 w-[90vw]"
              style={{
                height: iframeHeight6, // Fixed container height
                overflow: "hidden", // Ensure no content spills outside
                position: "relative",
              }}
            >
              <iframe
                title="Dukascopy"
                
                src="https://freeserv.dukascopy.com/2.0/?path=economic_calendar_new/index&lang=en&showHeader=false&tableBorderColor=%23D92626&defaultTimezone=0&defaultCountries=c%3AAU%2CCA%2CCH%2CCN%2CEU%2CGB%2CJP%2CNZ%2CUS%2CDE%2CFR%2CIT%2CES&impacts=1%2C2&dateTab=2&dateFrom=1459036800000&dateTo=1459555200000&showColCountry=true&showColCurrency=true&showColImpact=true&showColPrevious=true&showColForecast=true&width=100%25&height=500&adv=popup"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
                className=" block    border-[1px]  border-slate-300  sm:border-[0px]  w-[95vw] sm:w-[100vw] md:w-[100vw] lg:w-[100vw] xl:w-[45vw] rounded-2xl"
                // style={{ height: iframeHeight4 }}
                style={{
                  width: "100%", // Fixed iframe width
                  height: "100%", // Fixed iframe height
                  transform: `scale(${scale6}) translate(${
                    -((scale6 - 1) / scale6) * 50
                  }%, ${-((scale6 - 1) / scale6) * 50}%)`,
                  transformOrigin: "top left", // Anchor zoom at top left
                }}
              ></iframe>
            </div>
          )}
        </div>

       
      </div> */}
      {/* <-----widgets links start-----> */}
      <div className="flex justify-around items-center w-full flex-col sm:flex-col md:flex-col lg:flex-col xl:flex-row mt-6">
        <div>
          <div className="flex justify-around items-end w-[90vw] sm:w-[90vw] md:w-full">
            <div className="py-1 px-4 border border-gray-300 rounded">
              <div className="flex items-center space-x-2 cursor-pointer">
                <img
                  src={reduce_icon}
                  alt="reduce_icon"
                  className="h-[20px] w-[20px] mr-2 mt-1 font-bold "
                  onClick={() => setIframeHeight4("16vh")}
                  title="reduce height"
                />
                <img
                  src={reset_icon}
                  alt="reset_icon"
                  className="h-[20px] w-[20px] mr-2 mt-1 font-bold "
                  onClick={() => setIframeHeight4("38vh")}
                  title="reset height"
                />
              </div>
            </div>
            <div>
              <h2 className="text-black font-bold text-[14px] sm:text-[14px] md:text-[16px]"> Widget Trading View </h2>
            </div>
            <div className="flex justify-center mt-3 space-x-4">
              <div className="py-1 px-4 border border-gray-300 rounded">
                <div className="flex items-center space-x-2">
                  <HiOutlineZoomIn
                    onClick={zoomIn3}
                    className="cursor-pointer w-6 h-6"
                    title="zoomin"
                  />

                  <HiOutlineZoomOut
                    onClick={zoomOut3}
                    className="cursor-pointer w-6 h-6"
                    title="zoomout"
                  />
                </div>
              </div>
              <button
                onClick={toggleVisibility3}
                className=" px-4 py-1 bg-[#0059bf] text-white rounded hover:bg-[#023065] transition"
              >
                {isVisible3 ? "Hide" : "Unhide"}
              </button>
            </div>
          </div>

          {isVisible3 && (
            <div
              className="flex justify-center items-center mt-1 w-[95vw] sm:w-[95vw] md:w-[95vw] lg:w-[95vw] xl:w-[45vw]"
              style={{
                height: iframeHeight4, // Fixed container height
                overflow: "hidden", // Ensure no content spills outside
                position: "relative",
              }}
            >
              <iframe
                title="Trading view"
                src="https://www.tradingview-widget.com/embed-widget/events/?locale=en#%7B%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22width%22%3A400%2C%22height%22%3A550%2C%22importanceFilter%22%3A%22-1%2C0%2C1%22%2C%22countryFilter%22%3A%22ar%2Cau%2Cbr%2Cca%2Ccn%2Cfr%2Cde%2Cin%2Cid%2Cit%2Cjp%2Ckr%2Cmx%2Cru%2Csa%2Cza%2Ctr%2Cgb%2Cus%2Ceu%22%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22events%22%2C%22page-uri%22%3A%22www.tradingview.com%2Fwidget-wizard%2Fen%2Flight%2Feconomic-calendar%2F%22%7D"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
                className=" block    border-[1px]  border-slate-300  sm:border-[0px]  w-[95vw] sm:w-[100vw] md:w-[100vw] lg:w-[100vw] xl:w-[45vw] rounded-2xl"
                // style={{ height: iframeHeight4 }}
                style={{
                  width: "100%", // Fixed iframe width
                  height: "100%", // Fixed iframe height
                  transform: `scale(${scale3}) translate(${
                    -((scale3 - 1) / scale3) * 50
                  }%, ${-((scale3 - 1) / scale3) * 50}%)`,
                  transformOrigin: "top left", // Anchor zoom at top left
                }}
              ></iframe>
            </div>
          )}
        </div>

        <div>
          
          <div className="ml-[12%] sm:ml-[12%] md:ml-0 flex w-[90vw] sm:w-[90vw] md:w-[95vw] lg:w-[95vw] xl:w-[45vw] justify-center items-center">
            <div className="py-1 px-4 border border-gray-300 rounded">
              <div className="flex items-center space-x-2 cursor-pointer">
                <img
                  src={reduce_icon}
                  alt="reduce_icon"
                  className="h-[20px] w-[20px] mr-2 mt-1 font-bold "
                  onClick={() => setIframeHeight5("8vh")}
                  title="reduce height"
                />
                <img
                  src={reset_icon}
                  alt="reset_icon"
                  className="h-[20px] w-[20px] mr-2 mt-1 font-bold "
                  onClick={() => setIframeHeight5("38vh")}
                  title="reset height"
                />
              </div>
            </div>
            <div className="mx-[3%] mt-5 sm:mt-5 md:mt-0">
              <h2 className="text-black font-bold text-[14px] sm:text-[14px] md:text-[16px] "> Widget Investing.com </h2>
            </div>
            <div className="flex justify-center mt-3 space-x-4">
              <div className="py-1 px-4 border border-gray-300 rounded">
                <div className="flex items-center space-x-2">
                  <HiOutlineZoomIn
                    onClick={zoomIn4}
                    className="cursor-pointer w-6 h-6"
                    title="zoomin"
                  />

                  <HiOutlineZoomOut
                    onClick={zoomOut4}
                    className="cursor-pointer w-6 h-6"
                    title="zoomout"
                  />
                </div>
              </div>
              <button
                onClick={toggleVisibility4}
                className=" px-4 py-1 bg-[#0059bf] text-white rounded hover:bg-[#023065] transition"
              >
                {isVisible4 ? "Hide" : "Unhide"}
              </button>
            </div>
          </div>

          {isVisible4 && (
            <div
              className="flex justify-center items-center mt-1 w-[122vw] sm:w-[122vw] md:w-[95vw] lg:w-[95vw] xl:w-[45vw]"
              style={{
                height: iframeHeight5, // Fixed container height
                overflow: "hidden", // Ensure no content spills outside
                position: "relative",
              }}
            >
              <iframe
                title="Uk investing widget"
                src="https://sslecal2.investing.com?columns=exc_flags,exc_currency,exc_importance,exc_actual,exc_forecast,exc_previous&features=datepicker,timezone&countries=25,6,72,22,17,10,35,43,12,4,5&calType=week&timeZone=23&lang=56"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
                className=" block   border-[1px]  border-slate-300  sm:border-[0px]  sm:w-[100vw] md:w-[100vw] lg:w-[100vw] xl:w-[45vw] rounded-2xl w-[100%] "
                // style={{border:2px solid #4A4A4A}}
                // style={{ height: iframeHeight5 }}
                style={{
                  width: "78%", // Fixed iframe width
                  height: "100%", // Fixed iframe height
                  transform: `scale(${scale4}) translate(${
                    -((scale4 - 1) / scale4) * 50
                  }%, ${-((scale4 - 1) / scale4) * 50}%)`,
                  transformOrigin: "top left", // Anchor zoom at top left
                }}
              ></iframe>
              {/* <div>Hello World</div> */}
              {/* <AdminTest/> */}
            </div>
          )}
        </div>
      </div>

      <hr
        className="my-6"
        style={{ borderColor: "#2932a5", borderWidth: "1px" }}
      />
      {/* <-----widgets links end-----> */}

      {/* <------meta trader 5 links -----> */}
      {/* <div className="flex space-x-4 chart_button mt-10 justify-center items-center">
          <button
            onClick={() => setIframeHeight3("13vh")}
            className="px-4 py-1 bg-[#2932a5] text-white rounded hover:bg-blue-600"
          >
            Reduce Height
          </button>
          <button
            onClick={() => setIframeHeight3("38vh")}
            className="px-4 py-1 bg-gray-500 text-white rounded hover:bg-gray-600"
          >
            Reset Height
          </button>
      </div> */}

      <h2 className="text-black flex items-center justify-center text-center font-bold">
        {" "}
        Metatrader Trading Window{" "}
      </h2>
      <div className="flex justify-center items-center mt-1 w-full ">
        <iframe
          title="Ig page"
          src={iframeSrc}
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen=""
          className=" block   border-[1px]  border-slate-300  sm:border-[0px]  w-[90vw] sm:w-[90vw] md:w-[90vw] lg:w-[90vw] xl:w-[90vw] rounded-2xl overflow-x-auto"
          // style={{border:2px solid #4A4A4A}}
          style={{ height: "68vh" }}
        ></iframe>
        {/* <div>Hello World</div> */}
        {/* <AdminTest/> */}
      </div>

      {/* Modal */}
      {showModal && (
        <div
          className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-[999] "
          onClick={handleClickOutside}
        >
          
          <div
            ref={modalRef}
            className="bg-[#efefef] relative rounded-[8px] p-0 sm:p-6 md:p-6 w-[100vw] sm:w-[100vw] md:w-[60vw] max-h-[90vh] overflow-auto"
          >
                     <button
                      onClick={Closemodal}
                      className="absolute top-8 right-8 h-4 w-4 text-gray-600 hover:text-gray-900"
                      title="Close"
                    > <img
                    src={crossicon}
                    alt="crossicon"
                    className="h-[12px] w-[12px]"
                  /></button>
            <div className="bg-[#f7f7f7] p-6 rounded-[8px]">
            <div className="p-6 max-w-[40rem] mx-auto bg-white rounded-lg shadow-xl mt-4">
              <h2 className="text-xl font-bold mb-4">Update Iframe URL -</h2>
              <input
                type="text"
                placeholder="Enter new iframe URL"
                value={newSrc}
                onChange={(e) => setNewSrc(e.target.value)}
                className="w-full border rounded-md p-2 mb-4 focus:outline-none"
              />
              <div className="flex justify-end space-x-2">
                <button
                  onClick={handleCloseModal}
                  className="px-4 py-1 bg-gray-400 text-white rounded-md"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSubmit}
                  className="px-4 py-1 bg-[#2932a5] text-white rounded-md"
                >
                  Update
                </button>
              </div>
            </div>

            {/* <--------margin calculatore----------> */}
            <div className="p-6 max-w-[40rem] mx-auto bg-white rounded-lg shadow-xl mt-4">
            {modalloading && (
        <div className="fixed bottom-0 text-center mb-2 flex items-center justify-end   w-full">
          <LineWave
            height="60"
            width="60"
            color="#111827"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            className=" w-10 h-10 flex items-center justify-end  mt-10 "
          />
        </div>
      )}
              <h1 className="text-black font-bold text-[14px] sm:text-[14px] md:text-[16px] text-[20px] ">
                Margin Calculator -
              </h1>
              
              <h2 className="text-[#f48f8f] text-[13px] mb-3 font-semibold">(Please fill all inputs)</h2>
              <div className="mb-8 flex items-start sm:items-start md:items-center justify-between flex-col sm:flex-col md:flex-row">
                <label className="text-md font-bold">Account Currency</label>
                <select
                  value={accountCurrency}
                  onChange={(e) => setAccountCurrency(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 w-[200px] outline-none"
                >
                  <option value="">Select Currency</option>
                  {currencies.map((currency) => (
                    <option key={currency} value={currency}>
                      {currency}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-8 flex items-start sm:items-start md:items-center justify-between flex-col sm:flex-col md:flex-row">
                <label className="text-md font-bold">Margin Ratio</label>
                <select
                  value={marginRatio}
                  onChange={(e) => setMarginRatio(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 w-[200px] outline-none "
                 
                >
                  <option value="">Select Ratio</option>
                  {marginRatios.map((ratio) => (
                    <option key={ratio} value={ratio}>
                      {ratio}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-8 flex items-start sm:items-start md:items-center justify-between flex-col sm:flex-col md:flex-row">
                <label className="w-[200px] sm:w-[200px] md:w-[100%] text-md font-bold">Minimum Lot Size (allowed to trade)</label>
                <input
                  type="text"
                  value={minimumLotSize}
                  onChange={(e) => setminimumLotSize(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 w-[200px] outline-none"
                  placeholder="Enter lot size"
                />
              </div>
              <div className="mb-8 flex items-start sm:items-start md:items-center justify-between flex-col sm:flex-col md:flex-row ">
                <label className="text-md font-bold">Currency Pair</label>
                <select
                  value={currencyPair}
                  onChange={(e) => setCurrencyPair(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 w-[200px] outline-none relative"
                >
                  <option value="">Select Pair</option>
                  {allPairs.map((pair) => (
                    <option key={pair} value={pair}>
                      {pair}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-8 flex items-start sm:items-start md:items-center justify-between flex-col sm:flex-col md:flex-row">
                <label className="text-md font-bold">Lot Type</label>
                <select
                  value={lotType}
                  onChange={(e) => setLotType(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 w-[200px] outline-none relative"
                >
                  <option value="">Select Type</option>
                  {all_lottypes.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-8 flex items-start sm:items-start md:items-center justify-between flex-col sm:flex-col md:flex-row">
                <div className=" flex flex-col ">
                <label className="text-md font-bold">
                  Current Price 1 {`(${currencyPair})`}
                </label>
                <span className="text-[#4000ff] text-[14px] pl-3 ">
                   {`(${livetradeTime_forsamecurrency})`}
               
                </span>
                </div>
                <input
                  type="text"
                  value={currenctprize_forsamecurrency}
                  onChange={(e) => setCurrenctprize_forsamecurrency(e.target.value)}
                  className="opacity-gray-200 border border-gray-300 rounded-md p-2 w-[200px] outline-none bg-[#ebedef] caret-transparent"
                  placeholder="Enter current price"
                />
              </div>

              <div className="mb-8 flex items-start sm:items-start md:items-center justify-between flex-col sm:flex-col md:flex-row">
                <div className=" flex flex-col ">
                <label className="text-md font-bold">
                  Current Price 2 {`(${getDerivedPair()})`}
                </label>
                <span className="text-[#4000ff] text-[14px] pl-3 ">
                   {`(${livetradeTime})`}
               
                </span>
                </div>
                <input
                  type="text"
                  value={currenctprize}
                  onChange={(e) => setCurrenctprize(e.target.value)}
                  className="opacity-gray-200 border border-gray-300 rounded-md p-2 w-[200px] outline-none bg-[#ebedef] caret-transparent"
                  placeholder="Enter current price"
                />
              </div>
              <div className="mb-8 flex items-start sm:items-start md:items-center justify-between flex-col sm:flex-col md:flex-row">
                {/* <label className="text-md font-bold">Trade Size {`(${lotType.split(' (')[0]})`} </label> */}
                <label className="text-md font-bold">
                Trade Size {lotType ? `(${lotType.split(' (')[0]})` : ''}
              </label>

                <input
                  type="text"
                  value={tradeSize}
                  onChange={(e) => setTradeSize(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 w-[200px] outline-none"
                  placeholder="Enter size"
                />
              </div>

              <div className="mb-8 flex items-start sm:items-start md:items-center justify-between flex-col sm:flex-col md:flex-row">
                <label className="text-md font-bold">Currency Units</label>
                <input
                  type="text"
                  value={currencyUnits}
                  // onChange={(e) => setCurrencyUnits(e.target.value)}
                  readOnly
                  className="bg-[#ebedef] caret-transparent border border-gray-300 rounded-md p-2 w-[200px] outline-none"
                  placeholder="Currency units"
                />
              </div>
              <hr />
              <div className="mb-4 mt-3 flex items-start sm:items-start md:items-center justify-between flex-col sm:flex-col md:flex-row">
                <label className="text-md font-bold">Margin Required</label>
                <input
                  type="text"
                  value={finalmarginvalue}
                  readOnly
                  // onChange={(e) => setFinalmarginvalue(e.target.value)}
                  className="caret-transparent font-bold border border-[#5894f733] bg-[#5894f733] rounded-md p-2 w-[200px] outline-none"
                  placeholder="Margin value"
                />
              </div>
              <div className="mb-4 mt-3 flex items-start sm:items-start md:items-center justify-between flex-col sm:flex-col md:flex-row">
                <label className="text-md font-bold">Pip Value</label>
                <input
                  type="text"
                  value={finalPipvalue}
                  readOnly
                  // onChange={(e) => setFinalPipvalue(e.target.value)}
                  className="caret-transparent font-bold border border-[#5894f733] bg-[#5894f733] rounded-md p-2 w-[200px] outline-none"
                  placeholder="Pip value"
                />
              </div>

           

             



             
             

              <hr />
             
              {/* <----new code----> */}
          {/* //portfolio balance */}
              <div className="mb-4 mt-3 flex items-start sm:items-start md:items-center justify-between flex-col sm:flex-col md:flex-row">
                <label className="text-md font-bold">Balance to trade</label>
                {/* <input
                  type="text"
                  value={portfolioBalance}
                  onChange={(e) => setPortfolioBalance(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 w-[200px] outline-none"
                  placeholder="Portfolio Balance"
                /> */}
                 <div className="relative">
              <div className="w-[40px] border border-gray-300 absolute inset-y-0 left-0 flex items-center justify-center bg-gray-100 text-gray-600 rounded-l-md">
                {currencySymbols[accountCurrency] || ""}
              </div>
              <input
                type="text"
                value={portfolioBalance}
                onChange={(e) => setPortfolioBalance(e.target.value)}
                className="border border-gray-300 rounded-md p-2 pl-14 w-[200px]  placeholder:pl-0 outline-none"
                placeholder="Portfolio Balance"
              />
            </div>
              </div>
              <div className="mb-8 flex items-start sm:items-start md:items-center justify-between flex-col sm:flex-col md:flex-row">
                <label className="text-md font-bold">Profit Target (%)</label>
                <input
                  type="text"
                  value={profitTarget}
                  onChange={(e) => setprofitTarget(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 w-[200px] outline-none"
                  placeholder="Profit Target %"
                />
              </div>
              <div className="mb-8 flex items-start sm:items-start md:items-center justify-between flex-col sm:flex-col md:flex-row">
                <label className="text-md font-bold">Loss Limit (%)</label>
                <input
                  type="text"
                  value={lossLimit}
                  onChange={(e) => setlossLimit(e.target.value)}
                  className=" appearance-none border border-gray-300 rounded-md p-2 w-[200px] outline-none"
                  placeholder="Loss Limit %"
                />
              </div>

              {/* //max tradelot */}
              <div className="mb-4 mt-3 flex items-start sm:items-start md:items-center justify-between flex-col sm:flex-col md:flex-row">
                <label className="text-md font-bold">Max tradable lots</label>
                <input
                  type="text"
                  value={maxLots}
                  readOnly
                  // onChange={(e) => setMaxLots(e.target.value)}
                  className="bg-[#ebedef] caret-transparent border border-gray-300 rounded-md p-2 w-[200px] outline-none"
                  placeholder="Max Lots"
                />
              </div>
              
              <div className="mb-8 flex items-start sm:items-start md:items-center justify-between flex-col sm:flex-col md:flex-row">
                <label className="text-md font-bold">Profit Target Pips</label>
                <input
                  type="text"
                  value={profitTargetPips}
                  readOnly
                  // onChange={(e) => setprofitTargetPips(e.target.value)}
                  className="bg-[#ebedef] caret-transparent border border-gray-300 rounded-md p-2 w-[200px] outline-none"
                  placeholder="Profit Target Pips"
                />
              </div>
             

              <div className="mb-8 flex items-start sm:items-start md:items-center justify-between flex-col sm:flex-col md:flex-row">
                <label className="text-md font-bold">Loss Limit Pips</label>
                <input
                  type="text"
                  value={lossLimitPips}
                  readOnly
                  // onChange={(e) => setlossLimitPips(e.target.value)}
                  className="bg-[#ebedef] caret-transparent border border-gray-300 rounded-md p-2 w-[200px] outline-none"
                  placeholder="Loss Limit Pips"
                />
              </div>
             

              

              <button
                onClick={handleCalculate}
                className="mt-4 w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
              >
                Calculate
              </button>
            </div>
            </div>

            {/* <-----------pip calculator-----------> */}

            {/* <div className="p-6 max-w-lg mx-auto bg-white rounded-lg shadow-xl mt-4">
              <h1 className="text-black font-bold text-[14px] sm:text-[14px] md:text-[16px] text-[20px] mb-3">
                Pip Calculator:-
              </h1>
              <div className="mb-8 flex items-center justify-between">
                <label className="text-md font-bold">Account Currency</label>
                <select
                  value={accountCurrency2}
                  onChange={(e) => setAccountCurrency2(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 w-[200px] outline-none"
                >
                  <option value="">Select Currency</option>
                  {currencies.map((currency) => (
                    <option key={currency} value={currency}>
                      {currency}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-8 flex items-center justify-between">
                <label className="text-md font-bold">Trade Size</label>
                <input
                  type="text"
                  value={tradeSize2}
                  onChange={(e) => setTradeSize2(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 w-[200px] outline-none"
                  placeholder="Enter size"
                />
              </div>

              <button
                onClick={handleCalculatePipValues}
                className="mt-4 w-full bg-[#08337a] text-white py-2 px-4 rounded-md hover:[#001d4b]"
              >
                Calculate
              </button>
            </div> */}

            {/* <div className="p-6 max-w-lg mx-auto bg-white rounded-lg shadow-xl mt-4">
              <h1 className="text-black font-bold text-[14px] sm:text-[14px] md:text-[16px] text-[20px] mb-3">
                Pip Calculations:-
              </h1>
             
            
              <table className="min-w-full table-auto border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="border border-gray-300 px-4 py-2">Currency</th>
              <th className="border border-gray-300 px-4 py-2">Price</th>
              <th className="border border-gray-300 px-4 py-2">Pip Value</th>
            </tr>
          </thead>
          
              <tbody>
           
                {pipResults.map(({ pair, price }) => (
              <tr key={pair}>
                <td className="border border-gray-300 px-4 py-2">{pair}</td>
                <td className="border border-gray-300 px-4 py-2">{price}</td>
              </tr>
            ))}
            </tbody>
            </table>
      
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Userbuysell;
