import React, { useState, useEffect } from "react";
import styles from "../components/tradeall.module.css"
import axios from "axios";

const Table = ({ optionvalue, maindata, hideEstimate , selectedCountry , selectedImpacts }) => {
  // const countrydata = ['UK', 'US', 'JP', 'AU'];
  const [matcheduserdata , setMatcheduserdata]= useState([]);

const handleEventClick = async (item) => {
    let eventName;
    let eventcountry = item.country;
    let eventimpact = item.impact;
    let eventdate = item.date;
    let eventtime = item.time;
    // let timeclickedurl = 1 ;
    // if (item.event.includes("(")) {
    //   eventName = item.event.substring(0, item.event.indexOf("(")).trim();
    // } else {
    //   eventName = item.event;
    // }

    
    const cleanEventName = (event) => {
     
      const monthPattern = /\((Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)(\/\d+)?\)/g;
      
      const cleanedEvent = event
          .replace(monthPattern, "") // Remove parentheses with month names and optional numbers like (aug/11)
          .replace(/\(\s*\)/g, "") 
          .trim();
      return cleanedEvent;
  };

  // Check if event contains parentheses and clean it
  if (item.event.includes("(")) {
      eventName = cleanEventName(item.event);
  } else {
      eventName = item.event;
  }

  console.log("eventName" , eventName)
    if (eventcountry === "UK" || eventcountry === "US" || eventcountry === "CA" ||
    eventcountry === "CH" || eventcountry === "EU" || eventcountry === "JP" ||
    eventcountry === "AU" || eventcountry === "NZ" || eventcountry === "DE" || 
    eventcountry === "IT" || eventcountry === "FR" )

    {
      localStorage.setItem(
        "eventnameandcountry:",
        JSON.stringify({ eventName, eventcountry , eventimpact  , eventdate , eventtime  })
      );   
      
      const url = new URL(window.location.origin + "/admin/eventshistory");
      url.searchParams.set("eventName", eventName);
      url.searchParams.set("eventcountry", eventcountry);
      url.searchParams.set("eventimpact", eventimpact);
      url.searchParams.set("eventtime", eventtime);
      url.searchParams.set("eventdate", eventdate);
      // url.searchParams.set("timeclickedurl", timeclickedurl);
     
    
      // Open the new tab with the constructed URL
      const newTab = window.open(url.toString(), "_blank");
      // const newTab = window.open("/admin/eventshistory", "_blank");
      if (newTab) {
        newTab.focus();
      } else {
        alert("Failed to open a new tab.");
      }

      // navigate("/eventshistory");
    } else {
      alert("Sorry, data is available  ");
    }
  };


  const matchedevents_with_oldtrades_foradmin = async ()=>{
    try{
      // const tokenfromls = localStorage.getItem("token");

      // const h = { authorization: tokenfromls };
      // const userid = localStorage.getItem("userregisterId")
      // console.log("userid" , userid)
      const response = await axios.get(`${process.env.REACT_APP_URL}/matchedevents_foradmin` )
      // console.log("response_admin------>" , response.data.matchedEvents)
      const olddataof_user = response.data.matchedEvents.map(event=>event._doc);
     // console.log("olddataof_user" , olddataof_user)
      // const final_old_highlightesuser = olddataof_user.flatMap(item=>item.trades)
      setMatcheduserdata(olddataof_user)
    }
    catch(error){
      console.log("error" , error)
    }
    // const response = axios.get("")
  }

  useEffect(()=>{
    matchedevents_with_oldtrades_foradmin();
  },[])
  return (
    <div
      className={` ${styles.maintableuser} max-w-[100%] w-[89%] lg:w-[84%] xl:w-[89%]
        fixed ml-0 sm:ml-0 md:ml-0 lg:ml-[6%] xl:ml-0 overflow-auto top-[61%] sm:top-[61%] md:top-[55%] lg:top-[37%] xl:top-[27%]`}
    >
      <div className="overflow-auto">
       
      <div className="overflow-x-auto overflow-y-auto  max-h-[46vh] sm:max-h-[46vh] md:max-h-[48vh] lg:max-h-[62vh] xl:max-h-[72vh]">
            {maindata.length === 0 ? (
              <div className={`${styles.data_missing} m-2 mt-[40%] sm:mt-[40%] md:mt-10 lg:mt-5 xl:mt-5 text-center font-semibold text-xl `}>
                Sorry!! Data for this day is not available .
              </div>
            ) : (
              <table
              className={` ${styles.tableuser} min-w-full  rounded overflow-x-scroll m-auto text-[16px] font-semibold`}
            >
              <thead
                key={2}
                className="bg-[#d0dcf4]   text-[#170E32]  sticky top-0 z-10 text-[16px] font-semibold border-b-[12px] border-solid border-[#F6F6F6] rounded-[8px]"
              >
                    <tr key={1} className="h-[96px]">
                    <th className="sticky top-0 py-1 px-1">S. No.</th>
                    <th  className=" sticky top-0 py-1 px-1">Date</th>
                    <th className=" sticky top-0 py-1 px-1">Time(GMT)</th>
                    <th className="sticky top-0 py-1 px-1">Country</th>
                    <th className="sticky top-0 py-1 px-1">Event</th>
                    <th className="sticky top-0 py-1 px-1">Currency</th>
                    <th className="sticky top-0 py-1 px-1">Previous</th>
                    <th className="sticky top-0 py-1 px-1">Estimate</th>
                    <th className="sticky top-0 py-1 px-1">Actual</th>
                    <th className="sticky top-0 py-1 px-1">Impact</th>
                    <th className="sticky top-0 py-1 px-1">Change</th>
                    <th className="sticky top-0 py-1 px-1">
                      Change Percentage
                    </th>
                  </tr>
                </thead>

                <tbody className="bg-[#F6F6F6]  z-0">
                  {maindata.filter(item =>
                  //  selectedCountry.length > 0 ? selectedCountry.includes(item.country) : true)
                  (selectedCountry.length > 0 ? selectedCountry.includes(item.country) : true) &&
                  // Check if impact is selected or not, and apply the filter
                  (selectedImpacts.length > 0 ? selectedImpacts.includes(item.impact) : true))
                  .map((item , index) =>
                    !hideEstimate ||
                    (hideEstimate &&
                      item.estimate !== null &&
                      item.estimate !== "") ? (
                        <tr
                        key={item._id}
                        className={` text-center border-y-[12px] border-solid border-[#F6F6F6] bg-white hover:bg-[#aecaf133]"
                        `}
                     
                      >
                      
                          <td className="py-1 px-2 min-w-[80px]">
                              {index + 1}.
                            </td>
                        <td className="py-1 px-1">
                          {item.date}
                        </td>
                        <td className="py-1 px-1">
                          {item.time}
                        </td>
                        <td  className="py-1 px-1">
                          {item.country}
                        </td>
                        <td
                          
                          className={`py-1 px-1 ml-[-5px] cursor-pointer ${
                            matcheduserdata.some((event) => event.event === item.event && event.country === item.country)
                              ? 'text-yellow-500' 
                              : 'text-blue-500' 
                          }`}
                          onClick={() => handleEventClick(item)}
                        >
                          {item.event}
                        </td>
                        <td  className="py-1 px-1">
                          {item.currency}
                        </td>
                        <td className="py-1 px-1">
                          {item.previous}
                        </td>
                        <td className="py-1 px-1">
                          {item.estimate}
                        </td>
                        <td className="py-1 px-1">
                          {item.actual}
                        </td>
                        <td className="py-1 px-1">
                          {item.impact}
                        </td>
                        <td className="py-1 px-1">
                          {item.change}
                        </td>
                        <td className="py-1 px-1">
                          {item.changePercentage}
                        </td>
                      </tr>
                    ) : <></>
                  )}
                </tbody>
              </table>
            )}
          </div>
       
      </div>

    
    </div>
  );
};

export default Table;
