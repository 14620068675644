import React, { useEffect, useState } from 'react';
import styles from "../../components/tradeall.module.css";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import RingLoader from "react-spinners/RingLoader";
import { IoArrowBack } from "react-icons/io5";
const UsersTradedata = () => {
  const navigate = useNavigate();
  const [allUserstrades, setAllUserstrades] = useState([]);
  const [Loading, setLoading] = useState(true);
  const getAllUsers = async () => {

    try {
      // const tokenfromls = localStorage.getItem("token");
      // const h = { authorization: tokenfromls };
      const response = await axios.get(`${process.env.REACT_APP_URL}/getuserssavedtradingdata`);
      console.log("response" , response)
      setLoading(false);
      const data1 = response.data.groupedUsers;
      setAllUserstrades(data1); 
    } catch (error) {
      console.log("error", error);
      setLoading(false)
    }

  };


  // const sortedallUserstrades = [...allUserstrades].sort(
  //   (a, b) => new Date(b.date) - new Date(a.date)
  // );
  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <>

<div className='flex justify-center items-center text-[18px] mt-4'><h1 className='font-bold m-5'>Selected trading data by different Users :-</h1></div>
    {
       Loading ?  <div className="flex items-center justify-center  mt-10 w-full">
       <RingLoader 
         height="80"
         width="80"
         color="#94A3B8"
         ariaLabel="bars-loading"
         wrapperStyle={{}}
         wrapperClass=""
         visible={true}
         className=" flex items-center justify-center  mt-10 w-[100%]"
       />
     </div> :   <div className="max-w-[100%] mx-[4%]  ">
        <div className="">
          <IoArrowBack className="mb-2 cursor-pointer " onClick={() => navigate('/admin/economicdata')} title="Back Button"/>
          {allUserstrades.map((user , index) => (
            <>
             
              <div className='flex '> 
                 <h1 className='font-bold'>{index+1}.) </h1>
              <h1 className="font-bold pl-3">UserName : {user.userDetails.name}</h1></div>
              <h1 className="font-bold">User Email Id :  {user.userDetails.email}</h1>
              <div className="overflow-auto mb-2">
              <div className="overflow-x-auto overflow-y-auto  max-h-[46vh] sm:max-h-[46vh] md:max-h-[48vh] lg:max-h-[62vh] xl:max-h-[72vh]">
              <table
                className={` ${styles.tableuser} min-w-full  rounded overflow-x-scroll m-auto text-[16px] font-semibold`}>
              <thead
                  key={2}
                  className="bg-[#d0dcf4]   text-[#170E32]  sticky top-0 z-10 text-[16px] font-semibold border-b-[12px] border-solid border-[#F6F6F6] rounded-[8px]">
                   <tr key={1} className="h-[96px]">
                    <th className="sticky top-0 py-2 px-4">S.No.</th>
                    <th className="sticky top-0 py-2 px-4">Date</th>
                    <th className="sticky top-0 py-2 px-4">Time</th>
                    <th className="sticky top-0 py-2 px-4">Country</th>
                    <th className="sticky top-0 py-2 px-4 min-w-[350px]">Event</th>
                    <th className="sticky top-0 py-2 px-4">Currency</th>
                    <th className="sticky top-0 py-2 px-4">Trade</th>
                    <th className="sticky top-0 py-2 px-4">Trade Grade</th>
                    <th className="sticky top-0 py-2 px-4">Investing.com Var name</th>
                    <th className="sticky top-0 py-2 px-4">Trading view Var name</th>
                    <th className="sticky top-0 py-2 px-4">Trading Economics Var name</th>
                    <th className="sticky top-0 py-2 px-4">Movement (Currency)</th>
                    <th className="sticky top-0 py-2 px-4">Movement (Correlation)</th>
                    <th className="sticky top-0 py-2 px-4">Flat (Currency)</th>
                    <th className="sticky top-0 py-2 px-4">Flat (Correlation)</th>
                    <th className="sticky top-0 py-2 px-4">Comments</th>
                    <th className="sticky top-0 py-2 px-4">Prediction</th>
                    <th className="sticky top-0 py-2 px-4">Trade log(profit/loss)</th>
                    <th className="sticky top-0 py-2 px-4">Trade Amount</th>
                    <th className="sticky top-0 py-2 px-4">Trade in time(seconds)</th>
                    <th className="sticky top-0 py-2 px-4">Trade comment</th>
                  </tr>
                </thead>
                <tbody className="bg-[#F6F6F6]  z-0">
                  {/* {user.documents.map((doc, index) => ( */}
                  {[...user.documents]
                    .sort((a, b) => new Date(b.date) - new Date(a.date)) 
                    .slice(0, 2) // Sort by date in descending order
                    .map((doc, index) => (



        //             <tr key={index} className=" hover:bg-gray-100 text-center">
        //               <td className="py-2 px-4">{index + 1}.</td>
        //               <td className="py-2 px-4">{dateObj.date}</td>
        //               <td className="py-2 px-4">
        //   {dateObj.latestDocument.trades.length === 0 ? (
        //     <div>No trades</div>
        //   ) : (
        //     dateObj.latestDocument.trades.map(trade => (
        //       <div key={trade._id}>
        //         <div>{trade.date}</div>
        //       </div>
        //     ))
        //   )}
        // </td>
        // <td className="py-2 px-4">
        //   {dateObj.latestDocument.trades.length === 0 ? (
        //     <div>No trades</div>
        //   ) : (
        //     dateObj.latestDocument.trades.map(trade => (
        //       <div key={trade._id}>
        //         <div>{trade.time}</div>
        //       </div>
        //     ))
        //   )}
        // </td>
        // <td className="py-2 px-4">
        //   {dateObj.latestDocument.trades.length === 0 ? (
        //     <div>No trades</div>
        //   ) : (
        //     dateObj.latestDocument.trades.map(trade => (
        //       <div key={trade._id}>
        //         <div>{trade.country}</div>
        //       </div>
        //     ))
        //   )}
        // </td>
        // <td className="py-2 px-4">
        //   {dateObj.latestDocument.trades.length === 0 ? (
        //     <div>No trades</div>
        //   ) : (
        //     dateObj.latestDocument.trades.map(trade => (
        //       <div key={trade._id}>
        //         <div>{trade.event}</div>
        //       </div>
        //     ))
        //   )}
        // </td>
        // <td className="py-2 px-4">
        //   {dateObj.latestDocument.trades.length === 0 ? (
        //     <div>No trades</div>
        //   ) : (
        //     dateObj.latestDocument.trades.map(trade => (
        //       <div key={trade._id}>
        //         <div>{trade.currency}</div>
        //       </div>
        //     ))
        //   )}
        // </td>
        // <td className="py-2 px-4">
        //   {dateObj.latestDocument.trades.length === 0 ? (
        //     <div>No trades</div>
        //   ) : (
        //     dateObj.latestDocument.trades.map(trade => (
        //       <div key={trade._id}>
        //         <div>{trade.trade}</div>
        //       </div>
        //     ))
        //   )}
        // </td>
        // <td className="py-2 px-4">
        //   {dateObj.latestDocument.trades.length === 0 ? (
        //     <div>No trades</div>
        //   ) : (
        //     dateObj.latestDocument.trades.map(trade => (
        //       <div key={trade._id}>
        //         <div>{trade.trade_grade}</div>
        //       </div>
        //     ))
        //   )}
        // </td>
        // <td className="py-2 px-4">
        //   {dateObj.latestDocument.trades.length === 0 ? (
        //     <div>No trades</div>
        //   ) : (
        //     dateObj.latestDocument.trades.map(trade => (
        //       <div key={trade._id}>
        //         <div>{trade.investing_name}</div>
        //       </div>
        //     ))
        //   )}
        // </td>
        // <td className="py-2 px-4">
        //   {dateObj.latestDocument.trades.length === 0 ? (
        //     <div>No trades</div>
        //   ) : (
        //     dateObj.latestDocument.trades.map(trade => (
        //       <div key={trade._id}>
        //         <div>{trade.trading_view}</div>
        //       </div>
        //     ))
        //   )}
        // </td>
        // <td className="py-2 px-4">
        //   {dateObj.latestDocument.trades.length === 0 ? (
        //     <div>No trades</div>
        //   ) : (
        //     dateObj.latestDocument.trades.map(trade => (
        //       <div key={trade._id}>
        //         <div>{trade.trading_economics}</div>
        //       </div>
        //     ))
        //   )}
        // </td>
        // <td className="py-2 px-4">
        //   {dateObj.latestDocument.trades.length === 0 ? (
        //     <div>No trades</div>
        //   ) : (
        //     dateObj.latestDocument.trades.map(trade => (
        //       <div key={trade._id}>
        //         <div>{trade.movement_currency}</div>
        //       </div>
        //     ))
        //   )}
        // </td>
        // <td className="py-2 px-4">
        //   {dateObj.latestDocument.trades.length === 0 ? (
        //     <div>No trades</div>
        //   ) : (
        //     dateObj.latestDocument.trades.map(trade => (
        //       <div key={trade._id}>
        //         <div>{trade.movement_correlation}</div>
        //       </div>
        //     ))
        //   )}
        // </td>
        // <td className="py-2 px-4">
        //   {dateObj.latestDocument.trades.length === 0 ? (
        //     <div>No trades</div>
        //   ) : (
        //     dateObj.latestDocument.trades.map(trade => (
        //       <div key={trade._id}>
        //         <div>{trade.flat_currency}</div>
        //       </div>
        //     ))
        //   )}
        // </td>
        // <td className="py-2 px-4">
        //   {dateObj.latestDocument.trades.length === 0 ? (
        //     <div>No trades</div>
        //   ) : (
        //     dateObj.latestDocument.trades.map(trade => (
        //       <div key={trade._id}>
        //         <div>{trade.flat_correlation}</div>
        //       </div>
        //     ))
        //   )}
        // </td>
        // <td className="py-2 px-4">
        //   {dateObj.latestDocument.trades.length === 0 ? (
        //     <div>No trades</div>
        //   ) : (
        //     dateObj.latestDocument.trades.map(trade => (
        //       <div key={trade._id}>
        //         <div>{trade.comments}</div>
        //       </div>
        //     ))
        //   )}
        // </td>
        // <td className="py-2 px-4">
        //   {dateObj.latestDocument.trades.length === 0 ? (
        //     <div>No trades</div>
        //   ) : (
        //     dateObj.latestDocument.trades.map(trade => (
        //       <div key={trade._id}>
        //         <div>{trade.trade_log}</div>
        //       </div>
        //     ))
        //   )}
        // </td>
        // <td className="py-2 px-4">
        //   {dateObj.latestDocument.trades.length === 0 ? (
        //     <div>No trades</div>
        //   ) : (
        //     dateObj.latestDocument.trades.map(trade => (
        //       <div key={trade._id}>
        //         <div>{trade.trade_time}</div>
        //       </div>
        //     ))
        //   )}
        // </td>
        // <td className="py-2 px-4">
        //   {dateObj.latestDocument.trades.length === 0 ? (
        //     <div>No trades</div>
        //   ) : (
        //     dateObj.latestDocument.trades.map(trade => (
        //       <div key={trade._id}>
        //         <div>{trade.trade_comment}</div>
        //       </div>
        //     ))
        //   )}
        // </td>

                    
        //             </tr>
                    <tr
                    key={index}
                    className={` text-center border-y-[12px] border-solid border-[#F6F6F6] 
                      bg-white hover:bg-[#aecaf133]
                    `}
                  
                  >
                      <td className="py-2 px-4">{index + 1}.)</td>
                      <td className="py-2 px-4">{doc.date}</td>
                      <td className="py-2 px-4">{doc.time || "No trades"}</td>
                      <td className="py-2 px-4">{doc.country || "No trades"}</td>
                      <td className="py-2 px-4">{doc.event || "No trades"}</td>
                      <td className="py-2 px-4">{doc.currency || "No trades"}</td>
                      <td className="py-2 px-4">{doc.trade || "No trades"}</td>
                      <td className="py-2 px-4">{doc.trade_grade || "No trades"}</td>
                      <td className="py-2 px-4 min-w-[150px]">{doc.investing_name || "No trades"}</td>
                      <td className="py-2 px-4 min-w-[150px]">{doc.trading_view || "No trades"}</td>
                      <td className="py-2 px-4 min-w-[150px]">{doc.trading_economics || "No trades"}</td>
                      <td className="py-2 px-4 min-w-[150px]">{doc.movement_currency || "No trades"}</td>
                      <td className="py-2 px-4">{doc.movement_correlation || "No trades"}</td>
                      <td className="py-2 px-4">{doc.flat_currency || "No trades"}</td>
                      <td className="py-2 px-4">{doc.flat_correlation || "No trades"}</td>
                      <td className="py-2 px-4 min-w-[380px]">{doc.comments || "No trades"}</td>
                      <td className="py-2 px-4">{doc.prediction || "No trades"}</td>
                      <td className="py-2 px-4">{doc.trade_log || "No trades"}</td>
                      <td className="py-2 px-4">{doc.trade_amount || "No trades"}</td>
                      <td className="py-2 px-4">{doc.trade_time || "No trades"}</td>
                      <td className="py-2 px-4 min-w-[250px]">{doc.trade_comment || "No trades"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
             </div>
              </>
          ))}
        </div>
      </div>
    }
   

    
    </>
  );
};

export default UsersTradedata;





{/* <tbody>
  {user.dates
    .filter(dateObj => dateObj.latestDocument.trades.length > 0) 
    .map((dateObj, index) => (
      <tr key={index} className="hover:bg-gray-100 text-center">
        <td className="py-2 px-4">{index + 1}.</td>
        <td className="py-2 px-4">{dateObj.date}</td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.map(trade => (
            <div key={trade._id}>
              <div>{trade.date}</div>
            </div>
          ))}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.map(trade => (
            <div key={trade._id}>
              <div>{trade.time}</div>
            </div>
          ))}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.map(trade => (
            <div key={trade._id}>
              <div>{trade.country}</div>
            </div>
          ))}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.map(trade => (
            <div key={trade._id}>
              <div>{trade.event}</div>
            </div>
          ))}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.map(trade => (
            <div key={trade._id}>
              <div>{trade.currency}</div>
            </div>
          ))}
        </td>
      </tr>
    ))}
</tbody> */}
