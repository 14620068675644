// App.js
import React, { useState , useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import EconomicsCalender from "./components/EconomicsCalender";
import Signup from "./pages/signup";
import Signin from "./pages/signin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Mongodata from "./components/Mongodata";
// import UserProtectedRoute from "./components/userProtectedRoute";
import ProtectedRoute from "./components/ProtectedRoute";
import ProtectedRouteUser from "./components/ProtectedRouteuser";
import { MyContext } from "./MyContext";
import Eventdetails from "./pages/eventdetails";
import Eventshistory from "./pages/eventshistory";
import matchtables from "./pages/matchtables";
import Signupuser from "./pages/user.jsx/signupuser";
import Signinuser from "./pages/user.jsx/signinuser";
import UserCalender from "./components/user/userCalender";
import savedtradeOptions from "./pages/user.jsx/savedtrade";
import Admindashboard from "./pages/admindashboard";
import graphdata from "./components/Candle";
import graphdataUser from "./components/CandleUser";
import Error from "./pages/Error";
import Forgetpassword from "./pages/user.jsx/forgetpassword";
import Verifyotp from "./pages/user.jsx/verifyotp"
import Changepassword from "./pages/user.jsx/changepassword";
import EventshistoryUser from "./pages/eventhistoryuser"
import AdminBuy_Sell from "./pages/adminbuysell";
import Widgetpage from "./pages/widgetpage"
import Userbuysell from "./pages/user.jsx/userbuysell";
import CommunityChat from "./pages/user.jsx/community"

import LiveCharts from "./pages/livecharts"


// import Average_movementPage from "./pages/averagemovement"
const App = () => {
  const [endpoint, setendPoint] = useState("getgbpdata");
  const [maindatedata, setMaindatedata] = useState("");


// <-----------Remove data from local storage------------>
  // function removeLocalStorageEvery24Hours() {
  //   const lastDeletionKey = 'lastDeletionTime';
  //   const currentTime = new Date().getTime();
  //   const lastDeletionTime = localStorage.getItem(lastDeletionKey);
  //   // const fifteenDaysInMilliseconds = 20 * 1000; 
  //   // const fifteenDaysInMilliseconds = 15 * 24 * 60 * 60 * 1000
  //   const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;
  //   if (lastDeletionTime && (currentTime - lastDeletionTime) >= twentyFourHoursInMilliseconds) {
  //     // localStorage.clear();

  //     Object.keys(localStorage).forEach((key) => {
  //       if (
  //           key.includes("Clickeddate_data_") || 
  //           key.includes("table3_historyData_") || 
  //           key.includes("clickedmodal_data_2ndpage_")
  //       ) {
  //           localStorage.removeItem(key);
  //       }
  //   });
  //   // localStorage.removeItem("token");
  //   // localStorage.removeItem("adminregisterId");
  //   localStorage.removeItem("lastDeletionTime");
  //   localStorage.removeItem("All_events_history");
  //   localStorage.removeItem("UserId");
  //   localStorage.removeItem("userId");
  //   localStorage.removeItem("selectedtime");
  //   localStorage.removeItem("eventnameandcountry:");
  //   localStorage.removeItem("userregisterId");
  //   localStorage.removeItem("username");
  //   localStorage.removeItem("usertoken");
  //   localStorage.removeItem("todayDate");
  //   localStorage.removeItem("previousDate_u_search_history");
  //   localStorage.removeItem("adminregisterId");
  //   localStorage.removeItem("4GraphDataobjects");
  //   localStorage.removeItem("todayDate");
  //     localStorage.setItem(lastDeletionKey, currentTime.toString());
  //   } else if (!lastDeletionTime) {
  //     localStorage.setItem(lastDeletionKey, currentTime.toString());
  //   }
  // }

  // useEffect(() => {
  //   // <----------------it will check this function in every 24 hrs------------->
  //   const intervalId = setInterval(removeLocalStorageEvery24Hours, 24 * 60 * 60 * 1000);
  //   // console.log("data deleted succeslly ")
  //   return () => clearInterval(intervalId); // Cleanup 
  // }, []);



//   function getLocalStorageSize() {
//     let totalSizeInBytes = 0;
//     Object.keys(localStorage).forEach((key) => {
//         const value = localStorage.getItem(key);
//         // console.log("value" , value)
//         if (value) {
//           totalSizeInBytes += value.length;
//         }
        
//     });
//     // console.log("totalSizeInBytes" , totalSizeInBytes) ;
//       // console.log("totalSizeInBytesin mb" , totalSizeInBytes.toFixed(2))
//     const totalSizeInMB = totalSizeInBytes / (1024 * 1024); 
//     return totalSizeInMB;
//     // return totalSize;

// }

// function checkAndClearLocalStorage() {
//     const maxSizeInBytes = 5 * 1024 * 1024; 
//     const totalSize = getLocalStorageSize();

//     if (totalSize >= maxSizeInBytes) {
//         // console.log('LocalStorage size exceeds the threshold. Clearing all data.');
//         localStorage.clear(); 
//     }
// }

// function setItemWithSizeCheck(key, value) {
//     localStorage.setItem(key, value);
//     checkAndClearLocalStorage();
// }
// const sizeInMB = getLocalStorageSize();
// // console.log(`d(2)} MB`);

// // Example usage:
// setItemWithSizeCheck('newKey', 'some large data here');

  
  return (
    <MyContext.Provider
      value={{ endpoint, setendPoint, maindatedata, setMaindatedata }}
    >
      <Router>
        <div className="">
          <Routes>
            <Route path="/" element={<Signinuser />} />
            <Route path="/admin" element={<Signin />} />
            <Route
              path="/admin/economicdata"
              element={<ProtectedRoute Component={EconomicsCalender} />}
            />
            {/* <Route
              path="/admin/movement_variants"
              element={<ProtectedRoute Component={Average_movementPage} />}/> */}

            <Route path="/user/signup" element={<Signupuser />} />
            <Route path="/admin/signup" element={<Signup />} />
            <Route
              path="/mongodbdata"
              element={<ProtectedRoute Component={Mongodata} />}
            />

            <Route
              path="/admin/eventshistory"
              element={<ProtectedRoute Component={Eventshistory} />}
            />

              <Route
              path="/user/eventshistory"
              element={<ProtectedRouteUser Component={EventshistoryUser} />}
            />

            <Route
              path="/admin/dashboard"
              element={<ProtectedRoute Component={Admindashboard} />}
            />
            <Route
              path="/admin/matchedevents"
              element={<ProtectedRoute Component={matchtables} />}
            />
            <Route
              path="/admin/graphdata"
              element={<ProtectedRoute Component={graphdata} />}
            />
            <Route
              path="/admin/buy_sell"
              element={<ProtectedRoute Component={AdminBuy_Sell} />}
            />
             <Route
              path="/user/graphdata"
              element={<ProtectedRouteUser Component={graphdataUser} />}
            />
            <Route
              path="/user/savedtradeOptions"
              element={<ProtectedRouteUser Component={savedtradeOptions} />}
            />

            <Route
              path="/eventdetails"
              element={<ProtectedRoute Component={Eventdetails} />}
            />
            <Route
              path="/user/economicdata"
              element={<ProtectedRouteUser Component={UserCalender} />}
            />
             <Route
              path="/user/livecharts"
              element={<ProtectedRouteUser Component={LiveCharts} />}
            />
             <Route
              path="/user/community_chat"
              element={<ProtectedRouteUser Component={CommunityChat} />}
            />
             <Route
              path="/user/buysell"
              element={<ProtectedRouteUser Component={Userbuysell} />}
            />
             <Route
              path="/user/forgetpassword"
              element={<Forgetpassword/>}
            />
            <Route
              path="/user/verifyotp"
              element={<Verifyotp/>}
            />
             <Route
              path="/user/changepassword"
              element={<Changepassword/>}
            />
             <Route
              path="/widgetpage"
              element={<Widgetpage/>}
            />
            <Route path="*" element={<Error Component={Error} />} />
          </Routes>
          <ToastContainer />
        </div>
      </Router>
    </MyContext.Provider>
  );
};

export default App;
